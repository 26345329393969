import React, { useState, useEffect } from 'react';
import { auth, firestore, functions } from '../firebase';
import { collection, doc, getDocs } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function CurrentClientAppointment({ selectedDate, selectedHour, selectedEmployee, onClose, setRefreshKey }) {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);

  useEffect(() => {
    const fetchClientsAndEmployees = async () => {
      const user = auth.currentUser;

      const clientsRef = collection(
        firestore,
        "customers",
        user.uid,
        "clients"
      );
      const clientsSnapshot = await getDocs(clientsRef);
      const clientsData = await Promise.all(
        clientsSnapshot.docs.map(async (doc) => {
          const clientData = doc.data();
          const carRef = collection(
            firestore,
            "customers",
            user.uid,
            "clients",
            doc.id,
            "car"
          );
          const carSnapshot = await getDocs(carRef);
          const carData = carSnapshot.docs.map((carDoc) => carDoc.data());
          return { id: doc.id, ...clientData, car: carData };
        })
      );

      const employeesRef = collection(
        doc(collection(firestore, "customers"), user.uid),
        "employees"
      );
      const employeesSnapshot = await getDocs(employeesRef);
      const employeesData = employeesSnapshot.docs.map((doc) => doc.data());

      setClients(clientsData);
      setEmployees(employeesData);
    };

    fetchClientsAndEmployees();
  }, []);

  const handleClientChange = (event) => {
    const selectedClientId = event.target.value;
    const selectedClient = clients.find(
      (client) => String(client.id) === selectedClientId
    );
    setSelectedClient(selectedClient);
    setSelectedCar(null);
    console.log("Selected client:", selectedClient);
  };

  const handleCarChange = (event) => {
    const selectedCarIndex = event.target.value;
    const selectedCar = selectedClient
      ? selectedClient.car[selectedCarIndex]
      : null;
    setSelectedCar(selectedCar);
    console.log("Selected car:", selectedCar);
  };

  const yourSubmitHandlerForCurrentClients = async (event) => {
    event.preventDefault();

    console.log("selectedClient in submit handler:", selectedClient);

    if (!selectedClient) {
      toast.error('Vă rugăm să selectati un client existent!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { zIndex: 50 }
      });
      return;
    }

    const formattedHour = selectedHour ? selectedHour.split("-")[0] : "";

    const appointmentData = {
      date: selectedDate,
      employeeTag: selectedEmployee,
      firstName: selectedClient.firstName,
      lastName: selectedClient.lastName,
      phone: selectedClient.phone,
      hour: formattedHour,
      position: selectedCar.position,
      clientID: selectedClient.id,
    };
   
    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations({ operation: 'add', data: appointmentData });
      console.log(result.data);
      toast.success('Programarea a fost adaugată cu succes!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          
      }
      });
      onClose();
      setRefreshKey(oldKey => oldKey + 1);
    } catch (error) {
      console.error("Error calling clientOperations function: ", error);
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { zIndex: 50 }
      });
    }
  };

  return (
    <form onSubmit={yourSubmitHandlerForCurrentClients} className="">
                <h3 className="text-lg mb-4 font-medium text-gray-900 dark:text-white"> Adaugă programare pentru clientul curent </h3> 
      <div className="col-span-6 sm:col-span-3">
        <div className="mb-2">
          <label
            htmlFor="client"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Client:
          </label>
          <select
            id="client"
            onChange={handleClientChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
          >
            <option value="">Alege un client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName}
              </option>
            ))}
          </select>
        </div>
        {selectedClient && (
          <div className="mb-2">
            <label
              htmlFor="car"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Masina:
            </label>
            <select
              id="car"
              onChange={handleCarChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
            >
              <option value="">Alege o masina</option>
              {selectedClient.car.map((car, index) => (
                <option key={index} value={index}>
                  {car.description} - {car.position}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-2">
          <label
            htmlFor="employee"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Mecanic:
          </label>
          <select
            id="employee"
            name="employee"
            value={selectedEmployee}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
            disabled
          >
            {employees.map((employee) => (
              <option key={employee.employeeTag} value={employee.employeeTag}>
                {employee.employeeName} {employee.employeeLast}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="items-center col-span-6 mt-2">
        <button
          type="submit"
          disabled={!selectedClient || !selectedCar}
          className="text-white bg-purple-700 mt-2 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Confirma
        </button>
      </div>
    </form>
    
  );
}