import React, { useState, useEffect } from 'react';
import BasicNav from "../components/basicNav";

const Review = () => {
    return (
        <div>
          <section className="bg-gray-50 fixed w-full dark:bg-gray-900 h-svh overflow-auto">
            
            <BasicNav/>
                 <div className="flex flex-col items-center justify-center px-6 py-2 mt-auto sm:top-10 mx-auto relative">
  
                 <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">

                </div>
             </div>
            </section>
        </div>
    );
};

export default Review;