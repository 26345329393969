
import { useEffect, useState, useCallback } from 'react';
import { firestore, auth, db, storage} from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, setDoc} from 'firebase/firestore'; 
import Loading from './loading';
import imageCompression from 'browser-image-compression';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


const EmployeeInterventions = () => {
    const [appointments, setAppointments] = useState([]);
    const [cars, setCars] = useState({});
    const [loading, setLoading] = useState(true); // Add a loading state


    const fetchAppointments = useCallback(async () => {
        setLoading(true); // Set loading to true when fetching starts

        const currentUser = auth.currentUser;
        const employeeRef = doc(firestore, 'employeesLogin', currentUser.uid);
        const employeeSnap = await getDoc(employeeRef);
        const employeeData = employeeSnap.data();

        if (employeeData && employeeData.parentUserId) {
            const parentUserRef = doc(firestore, 'customers', employeeData.parentUserId);
            const employeeTagRef = doc(parentUserRef, 'employees', employeeData.employeeTag);
            const employeeTagSnap = await getDoc(employeeTagRef);
            const employeeTagData = employeeTagSnap.data();

            const appointmentsRef = collection(firestore, 'customers', employeeData.parentUserId, 'appointments');
            const appointmentsQuery = query(appointmentsRef, where('employeeTag', '==', employeeTagData.employeeTag));
            const appointmentsSnap = await getDocs(appointmentsQuery);

            const fetchedAppointments = appointmentsSnap.docs.map(doc => doc.data());

            // Fetch cars for each appointment concurrently
            const carFetchPromises = fetchedAppointments.map(appointment => {
                const clientRef = collection(firestore, 'customers', employeeData.parentUserId, 'clients', appointment.clientID, 'car');
                const carQuery = query(clientRef, where('position', '==', appointment.position));
                return getDocs(carQuery);
            });

            const carSnaps = await Promise.all(carFetchPromises);

            const fetchedCars = {};
            carSnaps.forEach((carSnap, index) => {
                if (!carSnap.empty) {
                    const carData = carSnap.docs[0].data();
                    const carID = carSnap.docs[0].id;
                    fetchedCars[fetchedAppointments[index].clientID] = { ...carData, carID };
                }
            });
            setCars(fetchedCars);

            // Only update the state if the fetched appointments have changed
            if (JSON.stringify(fetchedAppointments) !== JSON.stringify(appointments)) {
                setAppointments(fetchedAppointments);
            }
            console.log('fetchedAppointments', fetchedAppointments);
            
        } else {
            console.error('parentUserId is undefined');
        }
        setLoading(false); // Set loading to false when fetching ends

    }, [appointments]);
    
    useEffect(() => {
        fetchAppointments();
    }, []); 

    const handleUpload = async (event, clientId, carID, date) => {
        const currentUser = auth.currentUser;
        const employeeRef = doc(firestore, 'employeesLogin', currentUser.uid);
        const employeeSnap = await getDoc(employeeRef);
        const employeeData = employeeSnap.data();
    const files = Array.from(event.target.files);

    for (const file of files) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(file, options);
            const storageRef = ref(storage, `customers/${auth.currentUser.uid}/clients/${clientId}/car/${carID}/${compressedFile.name}`);
            
            const uploadTask = uploadBytesResumable(storageRef, compressedFile);

            uploadTask.on('state_changed', 
                (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                }, 
                (error) => {
                    console.error('Error:', error);
                }, 
                async () => {
                    const user = auth.currentUser;
                    if (user) {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        const docRef = doc(db, `customers/${employeeData.parentUserId}/clients/${clientId}/car/${carID}/photos`, Date.now().toString());
                        await setDoc(docRef, { date: date, photoURL: downloadURL }, { merge: true });
                    } else {
                        console.log("No user is signed in.");
                    }
                  }
            );
        } catch (error) {
            console.error('Error:', error);
        }
    }
};
    return (
        <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
            <div className="p-4">
                <div className="grid gap-4 xl:grid-cols-1 2xl:grid-cols-1">
                    <div className="p-4 grid grid-cols-1 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
                        {loading ? (
                            <div className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
                            <Loading />
                        </div>
                        ) : (
                            appointments.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)).map(appointment => (
                                <div key={appointment.id} className="bg-slate-900 shadow overflow-hidden sm:rounded-lg my-4">
                                    <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                                        <div>
                                            <h2 className="text-lg leading-6 font-medium text-gray-950 dark:text-white">{appointment.date}</h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-400">Client ID: {appointment.clientID}</p>
                                        </div>
                                        <div>
                                    <button className="bg-purple-700 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-lg" onClick={() => document.getElementById('fileInput').click()}>
                                        Adauga poza
                                    </button>
                                    <input id="fileInput" type="file" capture="environment" style={{ display: 'none' }} onChange={(event) => handleUpload(event, appointment.clientID, cars[appointment.clientID]?.carID, appointment.date)} multiple />                               
                                      </div>
                                    </div>
                                    <div className="">
                                        <dl>
                                            <div className="bg-slate-600 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500 dark:text-white">Brand</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white">
                                                    {cars[appointment.clientID]?.description.split(' ')[0]}
                                                </dd>
                                            </div>
                                            <div className="bg-slate-600 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500 dark:text-white">Model</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white">
                                                    {cars[appointment.clientID]?.description.split(' ')[1]}
                                                </dd>
                                            </div>
                                            <div className="bg-slate-600 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500 dark:text-white">Nr. Inmatriculare</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white">{cars[appointment.clientID]?.position}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeInterventions;