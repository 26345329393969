import React from "react";
import { motion } from "framer-motion";
import AppointmentsToday from "./appointmentsToday";
import AppointmentsTomorrow from "./appointmnetsTomorrow";
import EmployeeList from "./employeeList";
import CalendarUserInfo from "./calendarUserInfo";
import SmartMetrics from "./smartMetrics";

const DashboardComp = () => {
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString('ro-RO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  const currentMonth = currentDate.toLocaleString('ro-RO', { month: 'long' });

  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const tomorrowDay = tomorrowDate.toLocaleDateString('ro-RO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.div
      id="main-content"
      className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <main>
        <div className="p-4">
          <motion.div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3" variants={containerVariants}>
            <motion.div
              className="p-4 flex flex-row md:flex-col justify-between gap-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white"
              variants={itemVariants}
            >
              <div>
                <h2 className="text-xl font-bold mb-4">Astăzi: {currentDay}</h2>
                <div className="flex flex-col items-start justify-between mb-4 overflow-y-auto h-96" id="upcomingAppointments">
                  <AppointmentsToday />
                </div>
              </div>
              <div>
                <h2 className="text-xl font-bold mb-4">Mâine: {tomorrowDay}</h2>
                <div className="flex flex-col items-start justify-between mb-4 overflow-y-auto h-96" id="upcomingAppointments">
                  <AppointmentsTomorrow /> 
                </div>
              </div>
            </motion.div>
            <motion.div
              className="p-4 bg-white border dark:text-white border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800"
              variants={itemVariants}
            >
              <h2 className="text-xl font-bold mb-4">În luna curentă: {currentMonth}</h2>
              <CalendarUserInfo />
              <div className="flex flex-wrap justify-between mt-4">
                <div className="w-full sm:w-1/2">
                  <h3 className="flex items-center"><span className="inline-block h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></span>peste 75%</h3>
                  <h3 className="flex items-center"><span className="inline-block h-2.5 w-2.5 rounded-full bg-yellow-500 mr-2"></span>între 50%-75%</h3>
                </div>
                <div className="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <h3 className="flex items-center"><span className="inline-block h-2.5 w-2.5 rounded-full bg-orange-500 mr-2"></span>între 25%-50%</h3>
                  <h3 className="flex items-center"><span className="inline-block h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></span>sub 25%</h3>
                </div>
              </div>
            </motion.div>
          </motion.div>
          <motion.div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-2" variants={containerVariants}>
            <motion.div
              className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white"
              variants={itemVariants}
            >
              <h2 className="text-xl font-bold mb-4">Mecanici</h2>
              <div className="flex flex-col w-full items-start justify-between mb-4" id="employee">
                <EmployeeList />
              </div>
            </motion.div>
            <motion.div
              className="p-4 bg-white border dark:text-white border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800"
              variants={itemVariants}
            >
              <h2 className="text-xl font-bold mb-4">Date statistice</h2>
              <div className="flex flex-col justify-between mb-4 overflow-y-auto">
                <SmartMetrics />
              </div>
            </motion.div>
          </motion.div>
        </div>
      </main>
    </motion.div>
  );
}

export default DashboardComp;