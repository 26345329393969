import { useState } from "react";
import { functions, httpsCallable } from "../firebase";
import carList from './car-list.json';
import { getFirestore, collection, addDoc, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

function DashboardClientsAddClient({ isOpen, onClose, setRefreshKey }) {
    const [clientType, setClientType] = useState('Persoana Fizica');
    const [inputValue, setInputValue] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [cnpcui, setCnpCui] = useState('');
    const [fetchedData, setFetchedData] = useState('');
    const adresaOficiala = useState('');
    if (!isOpen) {
        return null;
      }

      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const clientType = event.target.elements.clientType.value;
        const firstName = event.target.elements['first-name'].value;
        const lastName = event.target.elements['last-name'].value;
        const cnpcui = clientType === 'Persoana Juridica' ? event.target.elements["cnpcui"].value : null;
        const phone = event.target.elements.phone1.value;
        const model = event.target.elements.model.value;
        const marca = event.target.elements.marca.value;
        const description = marca + " " + model;
        const position = event.target.elements.position1.value;
        const email = event.target.elements.email.value;
        const itp = event.target.elements.itp.value;
        
        const clientData = {
          email,
          firstName,
          lastName,
          phone,
          clientType,
          adresaOficiala: clientType === 'Persoana Juridica' ? adresaOficiala : null,
          denumireOficiala: clientType === 'Persoana Juridica' ? fetchedData : null,
        };
    
        if (clientType === 'Persoana Juridica') {
          clientData.cnpcui = cnpcui;
        }
    
        const carData = {
          position,
          description,
          itp: {
            endDate: itp,
          }
        };
    
        const data = {
          clientData,
          carData,
        };
    
        try {
          const clientOperations = httpsCallable(functions, 'clientOperations');
          const result = await clientOperations({ operation: 'add', data: data });
          console.log(result.data);
          alert("Clientul a fost adaugat cu succes!");
          onClose();
          setRefreshKey(oldKey => oldKey + 1);
        } catch (error) {
          console.error("Error calling clientOperations function: ", error);
        }
      };
    
    


      const filteredCarList = carList.filter(car => 
        car.brand.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    
      const handleBrandChange = e => {
        setInputValue(e.target.value);
        setSelectedBrand(carList.find(car => car.brand === e.target.value));
      };

      const fetchData = async () => {
        if (cnpcui) {
            try {
                let cui = cnpcui;
    
                const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "cui": cui })
                });
    
                if (!response.ok) {
                  alert('CUI-ul introdus nu este valid');
    
                    throw new Error('Network response was not ok');
    
                }
    
                const data = await response.json();
                data.adresaOficiala = data.address;
                setFetchedData(data.denumire);
            } catch (error) {
                console.error('Error:', error);
            }
        }
      };
    return (
      <div className="fixed left-0 right-0 z-50 bg-slate-800/80 overflow-x-hidden overflow-y-auto top-0 md:inset-0 " id="editClientModal" aria-hidden="true"> 
      <div class="relative md:h-auto  flex justify-center items-center content-center h-screen w-full">
         {/* Modal content */}
         <div className=" bg-white rounded-lg mt-16 mb-5 shadow dark:bg-gray-800 w-1/2 xs:w-full md:w-full">
           {/* Modal header */}
           <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
               <h3 className="text-xl font-semibold dark:text-white">
                 Adaugă client
               </h3>
               <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                   <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                 </svg>
               </button>
             </div>
             <div className="p-6 space-y-6  ">
             <form id="addUsersForm" onSubmit={handleSubmit}>
              <div className="grid grid-cols-6 gap-6 pb-4 ">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="clientType" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                    Tip
                    <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                  </label>
                  <select name="clientType" id="clientType1" onChange={(e) => setClientType(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required>
                  <option value="Persoana Fizica">Persoana Fizica</option>
                    <option value="Persoana Juridica">Persoana Juridica</option>
                  </select>
                </div>
                {clientType === 'Persoana Juridica' && (
                <div className="col-span-6 sm:col-span-3">
                <label htmlFor="cnpcui" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">CUI <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                <input type="text" name="cnpcui" id="cnpcui" onBlur={fetchData} onChange={(e) => setCnpCui(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="RO12345678" required />
              </div>
                )}
                {clientType === 'Persoana Juridica' && (
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="den-firnma" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                      Denumire Firma
                      <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                    </label>
                    <input type="text" name="den-firma" id="denfirma" value={fetchedData} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Auto" required />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                    {clientType === 'Persoana Juridica' ? 'Prenume Reprezentant' : 'Prenume'}
                    <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                  </label>
                  <input type="text" name="first-name" id="firstName1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Ion" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                  {clientType === 'Persoana Juridica' ? 'Nume Reprezentant' : 'Nume'}
                  <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                    </label>
                  <input type="text" name="last-name" id="lastName1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Popescu" required />
                </div>
               
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Telefon <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  
                  <input type="text" name="phone1" id="phone1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="0712345678" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="text" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="adresa@exemplu.ro" />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="position" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Nr. Înmatriculare <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  <input type="text" name="position" id="position1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="B000EXE" required />
                </div>
                <div className="col-span-6 sm:col-span-3 ">
                  <label htmlFor="marca" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Marcă <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  <input 
                  id="marca" 
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                  placeholder="Renault"
                  value={inputValue}
                  onChange={handleBrandChange}
                  list="car-brands"
                  required
                />
               </div>
               <div className="col-span-6 sm:col-span-3">
                          <>
              <label htmlFor="model" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Model <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
              <input 
                id="model" 
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                placeholder="Megane"
                list="car-models"
                required
              />
            </>
              </div>
              <div className="col-span-6 sm:col-span-3">
                          <>
              <label htmlFor="itp" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Data expirare ITP <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
              <input 
                id="itp" 
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                placeholder="Megane"
                type="date"
                required
              />
            </>
              </div>
              </div>
              <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                <button  className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>
              </div>
            </form>
   </div>
           </div>
         </div>
       </div>
    );
}

export default DashboardClientsAddClient;