'use client'

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCarSide } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { GiFlatTire } from "react-icons/gi";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ro from 'date-fns/locale/ro';
import { format } from 'date-fns';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BasicNav from "../components/basicNav";
import Footer from '../components/footerMain';
import OpenLayersServiceLocations from '../components/GoogleMapsServiceLocations';
registerLocale('ro', ro);

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 30);

const EndUser = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [position, setPosition] = useState('');
  const [time, setTime] = useState('');
  const [step, setStep] = useState(1);
  const [service, setService] = useState('');
  const [code, setCode] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [timesForDate, setTimesForDate] = useState([]);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showGenerateCodeButton, setShowGenerateCodeButton] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state
  const [loading2, setLoading2] = useState(false); // Loading state

  const options = [
    { 
      value: 'itp', 
      label: 'ITP', 
      description: 'Description for ITP', 
      icon: FaCarSide 
    },
    { 
      value: 'vulcanizare', 
      label: 'Vulcanizare', 
      description: 'Description for Vulcanizare', 
      icon: GiFlatTire 
    }
  ];

  const fadeInUp = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { duration: 0.3, ease: "easeOut" }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.2, ease: "easeIn" }
    }
  };

  const staggerChildren = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedService('');
    setTimesForDate([]);
    setShowCalendar(false);
    const formattedDate = format(date, 'yyyy-MM-dd');
    console.log('Selected date:', formattedDate);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    const fetchData = async (retry = false) => {
      if (!selectedDate) return;
      setLoading(true);
      try {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const response = await axios.post('https://europe-west1-servicegest-8852e.cloudfunctions.net/getServices', {
          type: service,
          date: formattedDate,
        });

        if (response.status !== 200) {
          throw new Error('HTTP error ' + response.status);
        }

        setFetchedData(response.data);
      } catch (error) {
        console.error('Error calling getServices', error);
        setTimeout(() => {
          if (!retry) {
            console.log('Retrying fetch...');
            fetchData(true);
          }
        }, 10000);
       
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    fetchData();
  }, [selectedDate, service]);

  const handleServiceClick = async (serviceName) => {
    setSelectedService(serviceName);
    if (!selectedDate) return;
    setLoading2(true)
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    try {
      const response = await axios.post('https://europe-west1-servicegest-8852e.cloudfunctions.net/getClientCalendar', {
        name: serviceName,
        date: formattedDate,
        type: service,
      });

      if (response.status !== 200) {
        throw new Error('HTTP error ' + response.status);
      }

      const availableTimes = Object.entries(response.data)
        .filter(([time, available]) => available)
        .map(([time]) => time)

      setTimesForDate(availableTimes);
    } catch (error) {
      console.error('Error calling getClientCalendar', error);
    } finally {
      setTimeout(() => {
        setLoading2(false);
      }, 200);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedDate) {
      alert('Selectează data programării.');
      return;
    }

    const data = {
      numeService: selectedService,
      date: format(selectedDate, 'yyyy.MM.dd'),
      time: time,
      email: email,
      phone: phone,
      firstName: firstName,
      lastName: lastName,
      position: position,
      model: `${carBrand} ${carModel}`,
      tip: service,
    };

    try {
      const response = await fetch('https://europe-west1-servicegest-8852e.cloudfunctions.net/appointmentCreateEndp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
      toast.success('Codul de verificare a fost trimis pe numărul de telefon. Te rugăm să introduci codul primit pentru a confirma programarea.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1e293b',
          color: '#fff',
        }
      });
      setShowGenerateCodeButton(false);
      setStep(step + 1);
    } catch (error) {
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1e293b',
          color: '#fff',
        }
      });
      setStep(2);
      setSelectedDate(null);
    }
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();

    const data = {
      phone: phone,
      code: code,
    }

    try {
      const response = await fetch('https://europe-west1-servicegest-8852e.cloudfunctions.net/appointmentCreateEndp', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      toast.success('Programarea a fost facuta cu succes.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1e293b',
          color: '#fff',
        }
      });
      setTimeout(() => {
        window.location.reload();   
      }, 3000);
    } catch (error) {
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1e293b',
          color: '#fff',
        }
      });
    }
  };
  const scrollToDiv = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  };
  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-slate-900">
      <BasicNav />
      <motion.section 
        className="container mx-auto px-4 py-8 mt-10"
        initial="initial"
        animate="animate"
        exit="exit"
        id="progservice"
        variants={staggerChildren}
      >
        <motion.h1 
          className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-4"
          variants={fadeInUp}
          id="section3"
        >
          Programează-te la service
        </motion.h1>
        <motion.div 
          className="bg-gray-200 dark:bg-slate-800 rounded-lg p-4 max-w-2xl mx-auto"
          variants={fadeInUp}
        >
          <div className="space-y-6">
            <AnimatePresence mode="wait">
              {step === 1 && (
                <motion.div key="step1" {...fadeInUp} className="grid grid-cols-2 gap-4">
                  {options.map((option, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setService(option.value)}
                      className={`service p-4 rounded-lg flex flex-col items-center justify-center cursor-pointer ${
                        service === option.value 
                        ? 'bg-purple-600 text-white' 
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-purple-200 dark:hover:bg-purple-800'
                      }`}
                    >
                      <option.icon className="text-4xl mb-2" />
                      <p className="text-lg font-semibold">{option.label}</p>
                    </motion.div>
                  ))}
                </motion.div>
              )}

              {step === 2 && (
                <motion.div key="step2" {...fadeInUp} className="space-y-4">
                  {showCalendar ? (
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="p-2 rounded-lg z-50 custom-datepicker"
                      placeholderText="Selectează data"
                      minDate={new Date()}
                      maxDate={maxDate}
                      locale="ro"
                      filterDate={isWeekday}
                      inline
                    />
                  ) : (
                    <div className="flex justify-between items-center text-white">
                      <label 
                        htmlFor="time" 
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                      >
                        Data selectată: {selectedDate ? format(selectedDate, 'dd.MM.yyyy') : 'Nu s-a selectat o dată'}
                      </label>
                      <button 
                        onClick={() => {
                          
                          setSelectedDate(null);
                          setShowCalendar(true)}}
                        className="bg-purple-600 text-white  py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors duration-200"
                      >
                        Schimbă
                      </button>
                    </div>
                  )}
                  {selectedDate && fetchedData && (
                                    <div className="flex flex-col gap-2">
                                      {Object.values(fetchedData).flat().filter(item => item.time && item.progOnline !== false).length === 0 ? (
                                        <h1 className="text-center text-2xl text-gray-700 dark:text-gray-300">Urmează</h1>
                                      ) : (
                                        <>
                                          <OpenLayersServiceLocations fetchedData={fetchedData} />
                                  
                                          <label 
                                            htmlFor="time" 
                                            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                          >
                                            Lista service:
                                          </label>
                                          {loading ? (
                                            <label 
                                              htmlFor="time" 
                                              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                                            >
                                              Se incarca...
                                            </label>
                                          ) : (
                                            Object.values(fetchedData).flat().map((item) => (
                                              item.time && item.progOnline !== false && (
                                                <motion.div
                                                  key={`${item.name}-${item.address}`} // Better unique key
                                                  whileHover={{ scale: 1.02 }}
                                                  className={`p-4 rounded-lg cursor-pointer flex justify-between items-center ${
                                                    selectedService === item.name
                                                      ? 'bg-purple-600 text-white'
                                                      : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white'
                                                  }`}
                                                  onClick={() => {
                                                    setTime('');
                                                    handleServiceClick(item.name);
                                                  }}
                                                >
                                                  <div className="flex flex-col">
                                                    <h3 className="text-xl font-bold">{item.name}</h3>
                                                    <p>Locatie: {item.address}</p>
                                                    <p>Disponibil din: {selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'No date selected'}, {item.time}</p>
                                                  </div>
                                                </motion.div>
                                              )
                                            ))
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                  
                  {selectedDate && selectedService && (
                    <div>
                      <label htmlFor="time" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Ora:</label>
                      <select
                        id="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        required
                      >
                        {loading2 ? (
                          <option value="">Se incarcă...</option>
                        ) : (
                          <>
                            <option value="">Selectează ora</option>
                            {timesForDate.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  )}
                </motion.div>
              )}

              {step === 3 && (
                <motion.div key="step3" {...fadeInUp} className="space-y-4">
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Informații șofer</h1>
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Nume</label>
                    <input
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Prenume</label>
                    <input
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500  dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Număr telefon</label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                </motion.div>
              )}

              {step === 4 && (
                <motion.div key="step4" {...fadeInUp} className="space-y-4">
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Informații mașină</h1>
                  <div>
                    <label htmlFor="carBrand" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Marcă</label>
                    <input
                      type="text"
                      id="carBrand"
                      value={carBrand}
                      onChange={(e) => setCarBrand(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="carModel" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Model</label>
                    <input
                      type="text"
                      id="carModel"
                      value={carModel}
                      onChange={(e) => setCarModel(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Număr înmatriculare</label>
                    <input
                      type="text"
                      id="position"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  {showGenerateCodeButton && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleSubmit}
                      className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors duration-300"
                    >
                      Generare cod confirmare
                    </motion.button>
                  )}
                </motion.div>
              )}

              {step === 5 && (
                <motion.div key="step5" {...fadeInUp} className="space-y-4">
                  <div>
                    <label htmlFor="code" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Cod:</label>
                    <input
                      type="text"
                      id="code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                    />
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={handleCodeSubmit}
                    className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors duration-300"
                  >
                    Trimite programarea
                  </motion.button>
                </motion.div>
              )}
            </AnimatePresence>

            <motion.div 
              className="flex justify-between mt-4"
              variants={fadeInUp}
            >
              {step > 1 && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => {
                    setStep(step - 1);
                    if (step === 2) {
                      scrollToDiv();
                      setSelectedDate(null);
                      setShowCalendar(true);
                    }
                  }}
                  className="flex items-center justify-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300"
                >
                  <IoIosArrowBack className="mr-2" />
                  Înapoi
                </motion.button>
              )}
                                 {(step === 1 || (step < 4 && selectedDate)) && service && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={() => {
                      scrollToDiv();
                      setStep(step + 1);
                    }}
                    className="flex items-center justify-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg ml-auto transition-colors duration-300"
                  >
                    Înainte
                    <IoIosArrowForward className="ml-2" />
                  </motion.button>
                )}
            </motion.div>
          </div>
        </motion.div>
      </motion.section>

      <motion.div 
        className="container mx-auto px-4 py-8 mt-10"
        variants={fadeInUp}
        
      >
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 text-center">Notificări ITP</h2>
        <div className="bg-gray-200 dark:bg-slate-800 rounded-lg p-4 max-w-2xl mx-auto flex flex-col items-center">
          <p className="mb-4 text-gray-700 dark:text-gray-300 text-center">
            Acum puteți programa o notificare pentru expirarea ITP-ului vehiculului dvs.
          </p>
          <p className="mb-4 text-gray-700 dark:text-gray-300 text-center">
            Acest serviciu este gratuit.
          </p>
          <button
            onClick={() => window.location.href = '/notification'}
            className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none transition-colors duration-200"
          >
            Programează notificarea
          </button>
        </div>
      </motion.div>
      <ToastContainer position="bottom-center" />
      <Footer />
    </div>
  );
};

export default EndUser;