import React, { useState } from "react";
import { motion } from "framer-motion";
import CalendarContext from './CalendarContext';
import Calendar2 from "./calendar2";

const DashboardCalendar2 = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      };
    
      const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 100
          }
        }
      };

    return (
        <CalendarContext.Provider value={{ selectedDate, setSelectedDate }}>
            <motion.div
                id="main-content"
                className="relative w-full h-full p-4 overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <motion.div
                    key={refreshKey}
                    className=" bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white"
                    variants={itemVariants}
                >
                    <Calendar2 setRefreshKey={setRefreshKey} />
                </motion.div>
              
            </motion.div>
        </CalendarContext.Provider>
    );
};

export default DashboardCalendar2;