import { initializeApp } from "firebase/app";
import {getAuth,onAuthStateChanged, createUserWithEmailAndPassword,signInWithEmailAndPassword, signOut, EmailAuthProvider,} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, "europe-central2");
const db = getFirestore(app);
const realTimeDb = getDatabase(app);

export {
  db,
  app,
  auth,
  firestore,
  signOut,
  storage, onAuthStateChanged, functions, httpsCallable,createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, EmailAuthProvider,realTimeDb,
};