"use client"

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Switch from "react-switch";
import { auth, firestore } from "../firebase"; // Adjust the import path as needed
import { doc, getDoc, updateDoc } from "firebase/firestore";
import UserInfo from "./userInfo";
import UserCredentials from "./userCredentials";
import UserHours from "./userHours";
import EmployeeList from "./employeeList";
import AddEmployee from "./addEmployee";
import DashboardMecanic from "./dashboardMecanic";

const DashboardSettings = () => {
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nrOnline, setNrOnline] = useState(false);
  const [userHoursSet, setUserHoursSet] = useState(false);
  const handleUserHoursSet = () => {
    setUserHoursSet(true);
  };
  useEffect(() => {
    const storedHours = JSON.parse(localStorage.getItem('hours'));
    if (storedHours && storedHours.startHour && storedHours.endHour) {
      setUserHoursSet(true);
    }
  }, []);

  useEffect(() => {
    const fetchNrOnline = async () => {
      const user = auth.currentUser;
      if (user) {
        const customerRef = doc(firestore, "customers", user.uid);
        const customerDoc = await getDoc(customerRef);
        if (customerDoc.exists()) {
          setNrOnline(customerDoc.data().progOnline);
        }
      }
    };

    fetchNrOnline();
  }, []);

  const handleToggle = async () => {
    const user = auth.currentUser;
    if (user) {
      const customerRef = doc(firestore, "customers", user.uid);
      await updateDoc(customerRef, { "progOnline": !nrOnline });
      setNrOnline(!nrOnline);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.div
      id="main-content"
      className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <main className="p-4">
        <div className="grid gap-6 xl:grid-cols-2 2xl:grid-cols-4">
          <motion.div 
            className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm xl:col-span-2 dark:border-gray-700 dark:bg-gray-800 dark:text-white"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4">Informații Utilizator</h2>
            <UserInfo />
            <UserCredentials />
            <div className="mt-4">
              <label className="flex items-center cursor-not-allowed">
                <span className="mr-2">Programari Online: {nrOnline ? "Activat" : "Dezactivat"}</span>
                <Switch
                  onChange={handleToggle}
                  checked={nrOnline}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                />
              </label>
            </div>
          </motion.div>
          <motion.div 
            className="p-6 bg-white border dark:text-white xl:col-span-2 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800"
            variants={itemVariants}
          >
            <h2 className="text-2xl font-semibold mb-4">Program de Lucru</h2>
            <UserHours onSet={handleUserHoursSet} />
            <motion.div 
              className="mt-8 p-6 bg-gray-100 dark:bg-slate-900 rounded-lg"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-semibold mb-4">Gestionare Mecanici</h2>
              <EmployeeList />
              <div className="flex flex-wrap gap-4 mt-4">
                <motion.button
                  onClick={() => setIsAddEmployeeModalOpen(true)}
                  className="flex items-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={!userHoursSet} 
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                  </svg>
                  Adaugă Mecanic
                </motion.button>
                <motion.button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                  </svg>
                  Creare Cont Mecanic
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </main>
      <AnimatePresence>
        {isAddEmployeeModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white dark:bg-gray-800/80 w-full h-screen overflow-y-auto p-6"
            >
              <AddEmployee
                isAddEmployeeModalOpen={isAddEmployeeModalOpen}
                setIsAddEmployeeModalOpen={setIsAddEmployeeModalOpen}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white dark:bg-gray-800 w-1/2 h-1/2 overflow-y-auto p-6"
            >
              <DashboardMecanic closeModal={() => setIsModalOpen(false)} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default DashboardSettings;