import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import BasicNav from "../components/basicNav";
import LoadingComp from '../components/loading';
import { ArrowLeft } from 'lucide-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const inputVariants = {
  focus: { scale: 1.05, transition: { duration: 0.3 } },
  blur: { scale: 1, transition: { duration: 0.3 } }
};

const buttonVariants = {
  hover: { scale: 1.05, transition: { duration: 0.3 } },
  tap: { scale: 0.95, transition: { duration: 0.3 } }
};

const formVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

export default function LoginPage() {
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    userName: '',
    userPhone: '',
    userCui: '',
    userAddress: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const toggleForm = () => setIsRegister(!isRegister);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, formData.email.trim(), formData.password);
      const user = userCredential.user;
      const db = getFirestore();

      const employeeRef = doc(db, "employeesLogin", user.uid);
      const employeeSnapshot = await getDoc(employeeRef);

      let role = '';
      if (employeeSnapshot.exists() && employeeSnapshot.data().role === 'employee') {
        role = 'employee';
      }

      const q = query(collection(db, "customers", user.uid, "subscriptions"), where("role", "==", "premium"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        navigate('/dashboard');
      } else if (role === 'employee') {
        navigate('/employee');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.error("Eroare la autentificare: ", error.message);
      alert('A apărut o eroare. Vă rugăm să încercați din nou.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "cui": formData.userCui })
      });

      if (!response.ok) {
        alert("Introduce un CUI valid");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
      const db = getFirestore();
      const userDocRef = doc(db, 'customers', user.uid);
      await setDoc(userDocRef, {
        email: formData.email,
        userName: formData.userName,
        userPhone: formData.userPhone,
        userCui: formData.userCui,
        address: formData.userAddress,
      });
      navigate('/pricing');
    } catch (error) {
      console.error("Error registering user: ", error.message);
      alert('A apărut o eroare. Vă rugăm să încercați din nou.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isRegister) {
      await handleRegister(event);
    } else {
      await handleLogin(event);
    }
  };

  const handleForgotPassword = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, formData.email);
      toast.success('S-a trimis linkul pentru resetarea parolei pe email!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1f2937',
          color: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }
      });
    } catch (error) {
      console.error('Error sending password reset email', error);
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1f2937',
          color: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="min-h-screen dark:bg-slate-900 flex flex-col bg-background text-foreground">
      <div className="fixed top-0 w-full z-10">
        <BasicNav />
      </div>
      
      {isLoading ? (
        <LoadingComp />
      ) : (
        <motion.section 
          className="flex-grow mt-20 flex items-center justify-center p-4"
          initial="hidden"
          animate="visible"
          variants={formVariants}
        >
          <div className="w-full max-w-md dark:bg-slate-800 rounded-lg shadow-xl p-8 space-y-8 dark:text-white">
            
            <button 
              onClick={handleBack} 
              className="mb-4 flex items-center text-purple-600 hover:text-purple-800 transition-colors duration-200"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Înapoi
            </button>
            <h1 className="text-3xl font-bold text-center">
              {isRegister ? "Creează un cont nou" : "Conectează-te"}
            </h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                <label htmlFor="email" className="block text-sm font-medium mb-1">
                  Adresă de email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 dark:text-black border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                  placeholder="adresa@exemplu.ro"
                  required
                />
              </motion.div>
              
              {isRegister && (
                <>
                  <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                    <label htmlFor="userName" className="block text-sm font-medium mb-1">
                      Denumire Comercială
                    </label>
                    <input
                      type="text"
                      name="userName"
                      id="userName"
                      value={formData.userName}
                      onChange={handleInputChange}
                      className="w-full dark:text-black px-3 py-2 border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                      placeholder="Auto-Service SRL"
                      required
                    />
                  </motion.div>
                  <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                    <label htmlFor="userPhone" className="block text-sm font-medium mb-1">
                      Nr. Telefon
                    </label>
                    <input
                      type="tel"
                      name="userPhone"
                      id="userPhone"
                      value={formData.userPhone}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 dark:text-black border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                      placeholder="0712345678"
                      required
                    />
                  </motion.div>
                  <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                    <label htmlFor="userAddress" className="block text-sm font-medium mb-1">
                      Adresa
                    </label>
                    <input
                      type="text"
                      name="userAddress"
                      id="userAddress"
                      value={formData.userAddress}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 dark:text-black  border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                      placeholder="Str. Exemplu, Nr. 1, Oras, Judet"
                      required
                    />
                  </motion.div>
                  <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                    <label htmlFor="userCui" className="block text-sm font-medium mb-1">
                      CUI
                    </label>
                    <input
                      type="text"
                      name="userCui"
                      id="userCui"
                      value={formData.userCui}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 dark:text-black border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                      placeholder="12345678"
                      required
                    />
                  </motion.div>
                </>
              )}
              
              <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                <label htmlFor="password" className="block text-sm font-medium mb-1">
                  Parola
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 dark:text-black border border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                  placeholder="••••••••"
                  required
                />
              </motion.div>
              
              {isRegister && (
                <motion.div variants={inputVariants} whileFocus="focus" whileBlur="blur">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium mb-1">
                    Confirmare Parolă
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border dark:text-black  border-input rounded-md shadow-sm focus:ring-ring focus:border-ring bg-background"
                    placeholder="••••••••"
                    required
                  />
                </motion.div>
              )}
              
              <motion.button
                type="submit"
                className="w-full flex justify-center bg-purple-800 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-foreground bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ring"
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                {isRegister ? "Creează cont" : "Conectează-te"}
              </motion.button>
            </form>
            
            {!isRegister && (
              <div className="mt-4 text-center">
                <button
                  onClick={handleForgotPassword}
                  type="button"
                  className="text-sm font-medium text-purple-600 hover:text-primary/80"
                >
                  Ai uitat parola?
                </button>
              </div>
            )}
            
            <p className="mt-2 text-center text-sm">
              {isRegister ? "Ai deja un cont?" : "Nu ai cont?"}
              {' '}
              <button
                type="button"
                onClick={toggleForm}
                className="font-medium text-purple-600"
              >
                {isRegister ? "Conectează-te aici" : "Creează cont aici"}
              </button>
            </p>
          </div>
          <ToastContainer />

        </motion.section>
      )}
    </div>
  );
}