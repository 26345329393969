import React, { useState, useEffect } from 'react';
import { auth, firestore, functions } from '../firebase';
import { collection, doc, getDocs } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import carList from './car-list.json';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export default function NewClientAppointment({ selectedDate, selectedHour, selectedEmployee, onClose, setRefreshKey }) {
  const [clientType, setClientType] = useState('Persoana Fizica');
  const [cnpcui, setCnpCui] = useState('');
  const [fetchedData, setFetchedData] = useState('');
  const [adresaOficiala, setAdresaOficiala] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const user = auth.currentUser;
      const employeesRef = collection(
        doc(collection(firestore, "customers"), user.uid),
        "employees"
      );

      const employeeSnapshot = await getDocs(employeesRef);

      const fetchedEmployees = employeeSnapshot.docs.map((doc) => ({
        employeeTag: doc.data().employeeTag,
        ...doc.data(),
      }));
      setEmployees(fetchedEmployees);
    };

    fetchEmployees();
  }, []);

  const handleSelectChange = (event) => {
    setClientType(event.target.value);
  };

  const filteredCarList = carList.filter(car => 
    car.brand.toLowerCase().startsWith(inputValue.toLowerCase())
  );

  const handleBrandChange = e => {
    setInputValue(e.target.value);
    setSelectedBrand(carList.find(car => car.brand === e.target.value));
  };

  const fetchData = async () => {
    if (cnpcui) {
      try {
        const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "cui": cnpcui })
        });

        if (!response.ok) {
          toast.error('CUI-ul introdus nu este valid', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { zIndex: 50 }
          });
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAdresaOficiala(data.address);
        setFetchedData(data.denumire);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const yourSubmitHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    const clientData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.nrTel,
      clientType: data.clientType,
      adresaOficiala: clientType === 'Persoana Juridica' ? adresaOficiala : null,
      denumireOficiala: clientType === 'Persoana Juridica' ? fetchedData : null,
    };

    if (clientType === 'Persoana Juridica') {
      clientData.cnpcui = data.cnpcui;
    }

    const carData = {
      position: data.position,
      description: `${data.marca} ${data.model}`,
    };

    const appointmentData = {
      date: selectedDate,
      hour: selectedHour ? selectedHour.split("-")[0] : "",
      employeeTag: selectedEmployee,
    };

    const submitData = {
      clientData,
      carData,
      appointmentData,
    };

    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations({ operation: 'add', data: submitData });
      console.log(result.data);
      toast.success('Programarea a fost adaugata cu succes!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: '#1f2937',
          color: '#fff',
          padding: '20px',
          borderRadius: '10px',
      }
      });
      onClose();
      setRefreshKey(oldKey => oldKey + 1);
    } catch (error) {
      console.error("Error calling clientOperations function: ", error);
      toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { zIndex: 50 }
      });
    }
  };

  return (
    <form id="newClientApp" onSubmit={yourSubmitHandler} className="grid grid-cols-6 gap-4 z-50 pb-10">
  <h3 className="col-span-6 text-lg mb-4 font-medium text-gray-900 dark:text-white"> Adaugă programare pentru un nou </h3> 
  <div className="col-span-6 sm:col-span-3">
        <label htmlFor="clientType" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          Tip<span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <select
          name="clientType"
          id="clientType1"
          onChange={handleSelectChange}
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          required
        >
          <option value="Persoana Fizica">Persoana Fizica</option>
          <option value="Persoana Juridica">Persoana Juridica</option>
        </select>
      </div>

      {clientType === 'Persoana Juridica' && (
        <>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="cnpcui" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
              CUI<span className="text-white text-xs opacity-50"> * obligatoriu</span>
            </label>
            <input
              type="text"
              id="cnpcui"
              name="cnpcui"
              placeholder="RO12345678"
              onChange={(e) => setCnpCui(e.target.value)}
              onBlur={fetchData}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
              required
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="denumire" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
              Denumire Firma<span className="text-white text-xs opacity-50"> * obligatoriu</span>
            </label>
            <input 
              type="text" 
              name="denumire" 
              id="denumire" 
              value={fetchedData} 
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
              placeholder="Auto" 
              required 
            />
          </div>
        </>
      )}

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="firstName" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          {clientType === 'Persoana Juridica' ? 'Prenume Reprezentant' : 'Prenume'}
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input
          type="text"
          name="firstName"
          id="firstName1"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          placeholder="Ion"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="lastName" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          {clientType === 'Persoana Juridica' ? 'Nume Reprezentant' : 'Nume'}
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input
          type="text"
          name="lastName"
          id="lastName1"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          placeholder="Popescu"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="position" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          Nr. Înmatriculare
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input
          type="text"
          id="position"
          name="position"
          placeholder="B000EXE"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="marca" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          Marcă
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input 
          id="marca" 
          name="marca"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
          placeholder="Renault"
          value={inputValue}
          onChange={handleBrandChange}
          list="car-brands"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="model" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          Model
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input 
          id="model" 
          name="model"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
          placeholder="Megane"
          list="car-models"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="nrTel" className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
          Telefon
          <span className="text-white text-xs opacity-50"> * obligatoriu</span>
        </label>
        <input
          type="text"
          id="nrTel"
          name="nrTel"
          placeholder="0712345678"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          required
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="adresa@exemplu.ro"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
        />
      </div>

      <div className="col-span-6 sm:col-span-3 hidden">
        <label htmlFor="ora" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Ora
        </label>
        <div className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white  dark:focus:ring-purple-500 dark:focus:border-purple-500">
          {selectedHour}
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3 hidden">
        <label htmlFor="data" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Data
        </label>
        <div id="data" name="data" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
          {selectedDate}
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="employee" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Mecanic
        </label>
        <select
          id="employee"
          name="employee"
          value={selectedEmployee}
          disabled
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
        >
          {employees.map((employee) => (
            <option key={employee.employeeTag} value={employee.employeeTag}>
              {employee.employeeName} {employee.employeeLast}
            </option>
          ))}
        </select>
      </div>

      <div className="items-center col-span-6">
        <button
        disabled ={!selectedBrand}
          className="text-white disabled:cursor-not-allowed disabled:opacity-50 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
          type="submit"
        >
          Confirma
        </button>
      </div>
    </form>
  );
}