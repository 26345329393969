"use client"

import { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { db, auth, httpsCallable, functions } from '../firebase'; // adjust the path to your firebase.js file
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import ClientEditModal from './dashboardClientEdit';
import DashboardClientsAddClient from './dashboardClientsAddClient';
import { FaUserPlus, FaTrash, FaEye } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DashboardClients2 = () => {
    const [clients, setClients] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [showModal, setShowModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const clientsStateRef = useRef([]);

    useEffect(() => {
        const userId = auth.currentUser.uid;
        const clientsFirestoreRef = collection(db, 'customers', userId, 'clients');

        localStorage.removeItem('clients');

        const unsubscribe = onSnapshot(clientsFirestoreRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const clientData = { id: change.doc.id, ...change.doc.data() };

                if (change.type === "added") {
                    if (!clientsStateRef.current.some(client => client.id === clientData.id)) {
                        clientsStateRef.current.push(clientData);
                    }
                }
                if (change.type === "removed") {
                    clientsStateRef.current = clientsStateRef.current.filter(client => client.id !== clientData.id);
                }
                if (change.type === "modified") {
                    const index = clientsStateRef.current.findIndex(client => client.id === clientData.id);
                    if (index !== -1) {
                        clientsStateRef.current[index] = clientData;
                    }
                }

                setClients([...clientsStateRef.current]);
                localStorage.setItem('clients', JSON.stringify(clientsStateRef.current));
            });
        });

        return () => unsubscribe();
    }, [refreshKey]);

    const handleClose = () => {
        setShowModal(null);
        setRefreshKey(prevKey => prevKey + 1);
    };

    const deleteClient = async (clientId, firstName, lastName) => {
        if (!clientId) return;

        const confirmDelete = () => new Promise((resolve) => {
            toast.warn(
                <div>
                    <p>{`Ești sigur că vrei să ștergi clientul ${firstName} ${lastName}?`}</p>
                    <div className="flex flex-row gap-4">
                    <button onClick={() => resolve(true)} className="mr-2 px-2 py-1 bg-green-600 text-white rounded">Da</button>
                    <button onClick={() => resolve(false)} className="px-2 py-1 bg-red-500 text-white rounded">Nu</button>
                    </div>

                </div>,
                {
                    position: "bottom-center",
                    autoClose: 100000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    closeButton: false,  
                    style: {
                        backgroundColor: '#1f2937',
                        color: '#fff',
                        padding: '20px',
                        borderRadius: '10px',
                    }
                }
            );
        });

        const confirmed = await confirmDelete();
        if (!confirmed) {
            return;
        }

        const data = {
            clientID: clientId,
        }

        try {
            const clientOperations = httpsCallable(functions, 'clientOperations');
            const result = await clientOperations({ operation: 'delete', data: data });
            console.log(result.data);
        
            console.log(`Client with id ${clientId} has been deleted.`);

            const userId = auth.currentUser.uid;
            const clientsFirestoreRef = collection(db, 'customers', userId, 'clients');
            const snapshot = await getDocs(clientsFirestoreRef);
            const newClients = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            clientsStateRef.current = newClients;
            setClients(newClients);
            localStorage.setItem('clients', JSON.stringify(newClients));

            toast.success(`Clientul ${firstName} ${lastName} a fost șters cu succes.`);
        } catch (error) {
            console.error('Error deleting client: ', error);
            toast.error('A apărut o eroare la ștergerea clientului. Vă rugăm să încercați din nou.');
        }
    };

    const filteredClients = clients.filter(client => 
        (client.clientType === "Persoana Juridica" ? client.denumireOficiala : `${client.firstName} ${client.lastName}`)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
    };
    
    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 100
          }
        }
    };

    return (
        <motion.div 
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={containerVariants}
            className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900 p-4"
        >
            <motion.div 
                className="mb-4 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
                variants={itemVariants}
            >
                <div className="flex justify-between items-center mb-4">
                    <motion.h1 
                        className="text-2xl font-semibold text-gray-800 dark:text-white"
                        variants={itemVariants}
                    >
                        Clienți
                    </motion.h1>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-300"
                        onClick={() => setIsModalOpen(true)}
                        variants={itemVariants}
                    >
                        <FaUserPlus className="mr-2" />
                        Adaugă Client
                    </motion.button>
                </div>
                <motion.input
                    type="text"
                    placeholder="Caută clienți..."
                    className="w-full p-2 mb-4 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variants={itemVariants}
                />
                <AnimatePresence>
                    {filteredClients.map((client, index) => (
                        <motion.div
                            key={client.id}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={itemVariants}
                            className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4 mb-4 flex justify-between items-center"
                        >
                            <div>
                                <motion.h2 
                                    className="text-xl font-semibold text-gray-800 dark:text-white"
                                    variants={itemVariants}
                                >
                                    {client.clientType === "Persoana Juridica" ? client.denumireOficiala : `${client.firstName} ${client.lastName}`}
                                </motion.h2>
                                <motion.p 
                                    className="text-gray-600 dark:text-gray-300"
                                    variants={itemVariants}
                                >
                                    {client.phone}
                                </motion.p>
                                {client.clientType === "Persoana Juridica" && (
                                    <motion.p 
                                        className="text-gray-600 dark:text-gray-300"
                                        variants={itemVariants}
                                    >
                                        {client.firstName} {client.lastName}
                                    </motion.p>
                                )}
                            </div>
                            <div className="flex space-x-2">
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="p-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors duration-300"
                                    onClick={() => setShowModal(client.id)}
                                >
                                    <FaEye />
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="p-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors duration-300"
                                    onClick={() => deleteClient(client.id, client.firstName, client.lastName)}
                                >
                                    <FaTrash />
                                </motion.button>
                            </div>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </motion.div>
            <DashboardClientsAddClient isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} refreshKey={refreshKey} setRefreshKey={setRefreshKey} />
            <ClientEditModal clientId={showModal} show={showModal !== null} onClose={handleClose} />
            <ToastContainer position="bottom-center" />
        </motion.div>
    );
}

export default DashboardClients2;