import { useEffect, useState, useCallback } from 'react';
import { firestore, auth } from '../firebase';
import { doc, getDoc, collection, query, where, onSnapshot} from 'firebase/firestore'; // adjust the path to your firebase.js file if necessary

import { format, startOfWeek, startOfMonth, endOfWeek, endOfMonth, differenceInDays, addDays, isSameMonth } from 'date-fns';

const DashboardMecanicUser = () => {
    const view = "week"; // or "month"
    const currentStartOfWeek = startOfWeek(new Date());

    const renderDays = () => {
        const dateFormat = "d";
        const days = [];

        let startDate;
        let numberOfDays;

        if (view === "week") {
            startDate = currentStartOfWeek;
            numberOfDays = 7;
        } else {
            const currentMonth = new Date();
            startDate = startOfWeek(startOfMonth(currentMonth));
            const endDate = endOfWeek(endOfMonth(currentMonth));
            numberOfDays = differenceInDays(endDate, startDate) + 1;
        }

        for (let i = 0; i < numberOfDays; i++) {
            const day = addDays(startDate, i);
            const isCurrentMonth = isSameMonth(day, new Date());
            const weekDays = ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"];
            const displayDay =
                view === "week" ? weekDays[day.getDay()] : format(day, dateFormat);
            const displayDate = format(day, "yyyy.MM.dd");

            days.push(
                <div
                    key={i}
                    className={`grid grid-rows-24 gap-2 ${
                        isCurrentMonth ? "" : "text-gray-400"
                    } ${view === "week" ? "sm:text-base md:text-lg lg:text-xl" : ""}`}
                >
                    <div
                        className={`p-2 text-black dark:text-white font-bold bg:text-white self-center text-center ${
                            view === "week" ? "sm:text-base md:text-lg lg:text-xl" : ""
                        }`}
                    >
                        {displayDay}
                        {view === "week" && <div>{displayDate}</div>}
                    </div>
                    {view === "week" && renderHours(day)}
                </div>
            );
        }

        return (
            <div
                className={`flex flex-col-reverse sm:grid ${
                    view === "week"
                        ? " text-white grid-cols-1 border border-gray-900 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7"
                        : "sm:grid-cols-7"
                } w-full`}
            >
                {days}
            </div>
        );
    };
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAppointment, setModalAppointment] = useState(null);
    const renderHours = (day) => {
        if (startHour === null || endHour === null) {
            return null;
        }

        const start = parseInt(startHour.split(":")[0]);
        const end = parseInt(endHour.split(":")[0]);

        const hours = [];
        for (let i = start; i < end; i++) {
            const startHour = String(i).padStart(2, "0");
            const endHour = String(i + 1).padStart(2, "0");
            const hourRange = `${startHour}:00-${endHour}:00`;

            // Find the appointment at this hour
            const appointment = appointments.find(appointment => {
                const appointmentDate = format(new Date(appointment.date), "yyyy.MM.dd");
                const appointmentHour = parseInt(appointment.hour.split(":")[0]);
                const startHourInt = parseInt(startHour);
                const endHourInt = parseInt(endHour);
                return appointmentDate === format(day, "yyyy.MM.dd") && appointmentHour >= startHourInt && appointmentHour < endHourInt;
            });
            
            hours.push(
                <div
                    key={i}
                    className={`p-2 m-1 text-center cursor-pointer ${appointment ? "bg-red-700" : "bg-green-700"}`}
                    onClick={() => {
                        if (appointment) {
                            setModalAppointment(appointment);
                            setIsModalOpen(true);
                        }
                    }}
                >
                    {hourRange}
                </div>
            );
        }

        return (
            <>
                {hours}
                {isModalOpen && (
                    <div>
                        <div
          className="fixed inset-0 flex items-center justify-center z-50 dark:bg-slate-900/20"
        >
          <div
          
            className="bg-white dark:text-white dark:bg-gray-800 p-6 rounded-lg w-4/5 max-w-lg"
            onClick={(e) => e.stopPropagation()}
          >

            <h2 className="text-xl font-bold mb-2">
            {modalAppointment?.date}
            </h2>
            <p className="mb-4">Ora: {modalAppointment?.hour}</p>
            <p className="mb-4">
              Client: {modalAppointment?.firstName} {modalAppointment?.lastName}{" "}
            </p>
            <p className="mb-4">Masina: {modalAppointment?.position}</p>
            <p className="mb-4">Nr. tel: {modalAppointment?.phone}</p>
            <div className="p-2 bg-purple-700  w-1/6 rounded-lg text-center "onClick={() => setIsModalOpen(false)}>Close</div>

                    </div>
                    
                </div>
            </div>
                )}
            </>
        );
    };
const [startHour, setStartHour] = useState(null);
const [endHour, setEndHour] = useState(null);


useEffect(() => {
    const fetchHours = async () => {
      const currentUser = auth.currentUser;
      const employeeRef = doc(firestore, 'employeesLogin', currentUser.uid);
      const employeeSnap = await getDoc(employeeRef);
      const employeeData = employeeSnap.data();
      const parentUserRef = doc(firestore, 'customers', employeeData.parentUserId);
      const parentUserSnap = await getDoc(parentUserRef);
      const parentUserData = parentUserSnap.data();
  
      setStartHour(parentUserData.startHour);
      setEndHour(parentUserData.endHour);
    };
  
    fetchHours();
  }, []);
const [appointments, setAppointments] = useState([]);

const fetchAppointments = useCallback(() => {
    const currentUser = auth.currentUser;
    const employeeRef = doc(firestore, 'employeesLogin', currentUser.uid);

    getDoc(employeeRef).then(employeeSnap => {
        const employeeData = employeeSnap.data();

        if (employeeData && employeeData.parentUserId) {
            const parentUserRef = doc(firestore, 'customers', employeeData.parentUserId);
            const employeeTagRef = doc(parentUserRef, 'employees', employeeData.employeeTag);

            getDoc(employeeTagRef).then(employeeTagSnap => {
                const employeeTagData = employeeTagSnap.data();

                const appointmentsRef = collection(firestore, 'customers', employeeData.parentUserId, 'appointments');
                const appointmentsQuery = query(appointmentsRef, where('employeeTag', '==', employeeTagData.employeeTag));

                onSnapshot(appointmentsQuery, (snapshot) => {
                    const fetchedAppointments = snapshot.docs.map(doc => doc.data());

                    if (JSON.stringify(fetchedAppointments) !== JSON.stringify(appointments)) {
                        setAppointments(fetchedAppointments);
                    }
                    console.log('fetchedAppointments', fetchedAppointments);
                });
            });
        } else {
            console.error('parentUserId is undefined');
        }
    });
}, [appointments]);
useEffect(() => {
    fetchAppointments();
}, []); 


    return (
        <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
            <div className="p-4">
                <div className="grid gap-4 xl:grid-cols-1 2xl:grid-cols-1">
                    <div className="p-4 grid grid-cols-1 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
                        {renderDays()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardMecanicUser;