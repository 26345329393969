import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { auth, onAuthStateChanged } from './firebase'
import { collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { firestore } from './firebase';
import LoginPage from './pages/loginPage';
import DashboardPage from './pages/dashboardPage';
import PricingPage from './pages/pricingPage';
import Loading from './components/loading';
import EndUser from './pages/endUser';
import EmployeePage from './pages/employeePage';
import Review from './pages/review';
import NotificationPage from './pages/NotificationPage';
import './App.css';
function App() {
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [userRole, setUserRole] = useState(null);
  
  useEffect(() => {
    const authInstance = auth;
    const db = firestore;  
  
    onAuthStateChanged(authInstance, async (user) => {
      if (user) {
        const userDocRef = doc(db, `customers/${user.uid}`);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists() && userDoc.data().role === 'dev') {
          setUserRole('dev');
        } else {
          setUserRole('standard');
        }
      } else {
        setUserRole(null);
      }
  
      setLoading(false);
      setAuthChecked(true);
    });
  }, []);

  if (loading || !authChecked) {
    return <Loading />;
  }

  return (
    <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard/*" element={userRole === 'dev' ? <DashboardPage /> : <Navigate to="/pricing" />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/" element={<EndUser/>} />
      <Route path="/notification" element={<NotificationPage /> }/>
      <Route path="/employee/*" element={<EmployeePage /> }/>
      <Route path="/review" element={<Review/>}/>
      <Route path="/loading" element={<Loading/>}/>
    </Routes>
  </Router>
  );
}

export default App;