import React, { useState, useEffect } from 'react';
import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ro';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { auth, firestore, functions } from '../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  getDoc,
  onSnapshot,
  deleteDoc,
  addDoc
} from "firebase/firestore";
import { getDatabase, ref, get, remove, push, set } from "firebase/database";
import { httpsCallable } from 'firebase/functions';
import NewClientAppointment from './NewClientAppointment';
import CurrentClientAppointment from './CurrentClientAppointment';
import CustomToolbar from './CustomToolbar';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


moment.locale('ro')
const localizer = momentLocalizer(moment);

localizer.startOfWeek = () => {
  return moment().startOf('week').add(1, 'days');
};

export default function EmployeeCalendar() {
  const [employees, setEmployees] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [blockedAppointments, setBlockedAppointments] = useState([]);
  const [startHour, setStartHour] = useState('08:00');
  const [endHour, setEndHour] = useState('20:00');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [isNewClient, setIsNewClient] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isBlockingMode, setIsBlockingMode] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      const { uid } = auth.currentUser;
      const q = query(
        collection(doc(collection(firestore, "customers"), uid), "employees")
      );
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map((doc) => doc.data());

      localStorage.setItem('employees', JSON.stringify(fetchedEmployees));

      setEmployees(fetchedEmployees);

      if (fetchedEmployees.length === 0) {
        alert('Vă rugăm să adăugați un mecanic în pagina de setări.');
      } else {
        setSelectedEmployee(fetchedEmployees[0]);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchAppointments = async () => {
      const user = auth.currentUser;

      if (user) {
        const appointmentsRef = collection(
          doc(collection(firestore, "customers"), user.uid),
          "appointments"
        );

        const querySnapshot = await getDocs(appointmentsRef);
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          start: new Date(doc.data().date.replace(/\./g, '-') + 'T' + doc.data().hour),
          end: new Date(doc.data().date.replace(/\./g, '-') + 'T' + moment(doc.data().hour, 'HH:mm').add(1, 'hour').format('HH:mm')),
          title: `${doc.data().firstName} ${doc.data().lastName}`,
        }));

        console.log("Fetched appointments:", data);
        setAppointments(data);

        const unsubscribe = onSnapshot(
          appointmentsRef,
          (snapshot) => {
            const newAppointments = snapshot.docChanges()
              .filter(({type}) => type === 'added')
              .map(({doc}) => ({
                ...doc.data(),
                id: doc.id,
                start: new Date(doc.data().date.replace(/\./g, '-') + 'T' + doc.data().hour),
                end: new Date(doc.data().date.replace(/\./g, '-') + 'T' + moment(doc.data().hour, 'HH:mm').add(1, 'hour').format('HH:mm')),
                title: `${doc.data().firstName} ${doc.data().lastName}`,
              }));

            if (newAppointments.length > 0) {
              setAppointments(prevAppointments => {
                const updatedAppointments = [...prevAppointments];
                newAppointments.forEach(newApp => {
                  const index = updatedAppointments.findIndex(app => app.id === newApp.id);
                  if (index === -1) {
                    updatedAppointments.push(newApp);
                  } else {
                    updatedAppointments[index] = newApp;
                  }
                });
                return updatedAppointments;
              });
            }
          }
        );

        return () => unsubscribe();
      }
    };

    fetchAppointments();
  }, [refreshKey]);

  useEffect(() => {
    const fetchBlockedAppointments = async () => {
      const user = auth.currentUser;
      console.log('User:', user.uid);
    
      if (user) {
        const db = getDatabase();
        const blockedAppointmentsRef = ref(db, `customers/${user.uid}/blocked`);
    
        try {
          const snapshot = await get(blockedAppointmentsRef);
          const data = [];
          snapshot.forEach((childSnapshot) => {
            const blockedData = childSnapshot.val();
            data.push({
              ...blockedData,
              id: childSnapshot.key,
              start: new Date(blockedData.date.replace(/\./g, '-') + 'T' + blockedData.hour),
              end: new Date(blockedData.date.replace(/\./g, '-') + 'T' + moment(blockedData.hour, 'HH:mm').add(1, 'hour').format('HH:mm')),
              title: 'Blocat',
              isBlocked: true,
            });
          });
    
          setBlockedAppointments(data);
          console.log("Fetched blocked appointments:", data);
        } catch (error) {
          console.error('Error fetching blocked appointments:', error);
        }
      }
    };
    
    fetchBlockedAppointments();
  }, [refreshKey]);

  useEffect(() => {
    const fetchHours = async () => {
      const user = auth.currentUser;

      if (user) {
        const storedHours = JSON.parse(localStorage.getItem('hours'));

        if (storedHours) {
          console.log('Fetching hours from local storage');
          setStartHour(storedHours.startHour);
          setEndHour(storedHours.endHour);
        } else {
          console.log('Fetching hours from Firestore');
          const userRef = doc(collection(firestore, "customers"), user.uid);

          const docSnapshot = await getDoc(userRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            localStorage.setItem('hours', JSON.stringify({ startHour: data.startHour, endHour: data.endHour }));

            setStartHour(data.startHour);
            setEndHour(data.endHour);
            console.log("Fetched hours:", data);
          }
        }
      }
    };

    fetchHours();
  }, []);

  const handleSelectEmployee = (event) => {
    const employee = employees.find(emp => emp.employeeTag === event.target.value);
    setSelectedEmployee(employee);
  };

  const filteredAppointments = [...appointments, ...blockedAppointments].filter(
    appointment => appointment.employeeTag === selectedEmployee?.employeeTag
  );

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: '#3b82f6',
      color: 'white',
      border: 'none',
      borderRadius: '0px',
      opacity: 0.8,
      display: 'block',
      padding: '10px',
      fontSize: '0.8em',
      overflow: 'hidden',
  
    };

    if (event.isBlocked) {
      style.backgroundColor = '#ef4444';
    }

    return { style };
  };

  const dayPropGetter = (date) => {
    const day = date.getDay();
    if (day === 0 || day === 6) {
      return {
        className: 'bg-red-300 dark:bg-red-900/50',
      };
    }
    return {};
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedDate(moment(slotInfo.start).format('YYYY.MM.DD'));
    setSelectedHour(moment(slotInfo.start).format('HH:mm'));
    setIsModalOpen(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedAppointment(event);
    setIsAppointmentModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAppointmentModalOpen(false);
    setSelectedAppointment(null);
    setIsBlockingMode(false);
  };

  const handleDeleteAppointment = async (appointment) => {
    const user = auth.currentUser;
    if (appointment && appointment.id) {
      if (appointment.isBlocked) {
        const db = getDatabase();
        const appointmentRef = ref(db, `customers/${user.uid}/blocked/${appointment.id}`);
        await remove(appointmentRef);
      } else {
        const docRef = doc(firestore, "customers", user.uid, "appointments", appointment.id);
        await deleteDoc(docRef);
      }
  
      setSelectedAppointment(null);
      setIsAppointmentModalOpen(false);
      setRefreshKey(oldKey => oldKey + 1);
    } else {
      console.error('No appointment selected or appointment ID is missing');
    }
  };
//TODO: blocare calendar in realtime db din FE 
  const handleBlockHours = async () => {
    const user = auth.currentUser;
    if (user && selectedEmployee && selectedDate && selectedHour) {
            const dayObject = {
        date: selectedDate,
        employeeTag: selectedEmployee.employeeTag,
        hour: selectedHour,
        type: "blocat"
      };

      console.log(dayObject);

      const db = getDatabase();
      const dbRef = ref(db, `customers/${user.uid}/blocked`);
      const newBlockRef = push(dbRef);
      try {
        await set(newBlockRef, dayObject);
        console.log('Hours blocked successfully'); 
        setRefreshKey(oldKey => oldKey + 1);
        toast.success('Calendarul a fost blocat cu succes!', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: '#1f2937',
            color: '#fff',
            padding: '20px',
            borderRadius: '10px',
        }
        });
        closeModal();
      } catch (error) {
        console.error('Error:', error);
        toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { zIndex: 50 }
        });
      }
    } else {
      console.error('Missing required information for blocking hours');
      toast.error('Informații lipsă pentru blocarea calendarului.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { zIndex: 50 }
      });
    }
  };

  return (
    <div className=" bg-white dark:bg-gray-800 rounded-lg">
      <div className="mb-4 flex justify-between items-center md:flex md:flex-col mt-4 md:text-center">
        <div className>
          <label htmlFor="employee-select" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Alege mecanicul
              </label>
          <select
            id="employee-select"
            className="block w-full mt-1 f rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            value={selectedEmployee?.employeeTag || ''}
            onChange={handleSelectEmployee}
          >
            <option value="">Alege mecanicul</option>
            {employees.map((employee) => (
              <option key={employee.employeeTag} value={employee.employeeTag}>
                {employee.employeeName} {employee.employeeLast}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedEmployee && (
        <div className="dark:text-white">
          <Calendar
        localizer={localizer}
        events={filteredAppointments}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 650 }}
        min={new Date(0, 0, 0, parseInt(startHour.split(':')[0]), parseInt(startHour.split(':')[1]))}
        max={new Date(0, 0, 0, parseInt(endHour.split(':')[0]), parseInt(endHour.split(':')[1]))}
        views={['day', 'week', 'month']}
        defaultView='day'
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        step={60}
        timeslots={1}
        formats={{
          weekdayFormat: (date, culture, localizer) =>
            localizer.format(date, 'dddd', culture),
        }}
        components={{
          toolbar: CustomToolbar, // Use the custom toolbar
        }}
        className="overflow-hidden h-screen"
      />
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-300 bg-opacity-30  dark:bg-gray-900 dark:bg-opacity-90 overflow-y-auto h-full rounded-lg w-full z-40" id="my-modal">
          <div className="relative top-5  mx-auto p-5 w-11/12 max-w-2xl shadow-lg rounded-lg bg-white dark:bg-gray-800">
                       <div className="">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                  Adaugă o programare sau blochează calendarul {selectedDate} la {selectedHour}
                </h3>
                <button
                  className="text-gray-900 dark:text-white text-2xl"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
                         <div className="mb-4 flex space-x-2">
                <button
                  className={`px-4 py-2 rounded-lg w-full ${isNewClient === true ? "bg-purple-900" : "bg-purple-700"} text-white`}
                  onClick={() => {
                    setIsNewClient(true);
                    setIsBlockingMode(false);
                  }}
                >
                  Client nou
                </button>
                <button
                  className={`px-4 py-2 rounded-lg w-full ${isNewClient === false ? "bg-purple-900" : "bg-purple-700"} text-white`}
                  onClick={() => {
                    setIsNewClient(false);
                    setIsBlockingMode(false);
                  }}
                >
                  Client existent
                </button>
                <button
                  className={`px-4 py-2 rounded-lg w-full ${isBlockingMode ? "bg-purple-900" : "bg-purple-700"} text-white`}
                  onClick={() => {
                    setIsNewClient(null);
                    setIsBlockingMode(true);
                  }}
                >
                  Blochează calendarul
                </button>
              </div>
              {isNewClient === true && (
                <NewClientAppointment
                  selectedDate={selectedDate}
                  selectedHour={selectedHour}
                  selectedEmployee={selectedEmployee?.employeeTag}
                  onClose={closeModal}
                  setRefreshKey={setRefreshKey}
                />
              )}
              {isNewClient === false && (
                <CurrentClientAppointment
                  selectedDate={selectedDate}
                  selectedHour={selectedHour}
                  selectedEmployee={selectedEmployee?.employeeTag}
                  onClose={closeModal}
                  setRefreshKey={setRefreshKey}
                />
              )}
              {isBlockingMode && (
                <div>
                  <p className="mb-2 text-gray-700 dark:text-gray-300">
                    Blochează pentru: {selectedDate} la {selectedHour}
                  </p>
                  <button
                    className="px-4 py-2 bg-purple-700 text-white rounded-lg w-full"
                    onClick={handleBlockHours}
                  >
                    Blochează
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isAppointmentModalOpen && selectedAppointment && (
        <div className="fixed inset-0  bg-gray-300 bg-opacity-30 rounded-lg dark:bg-gray-900 dark:bg-opacity-90 overflow-y-auto h-full w-full z-50" id="appointment-modal">
          <div className="relative top-20 mx-auto p-5 w-11/12 max-w-md shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="flex flex-col p-2 relative">
              <button
                className="absolute top-2 right-2 text-gray-900 dark:text-white text-2xl"
                onClick={closeModal}
              >
                &times;
              </button>
              <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-white">
                Data: {selectedAppointment.date}
              </h2>
              <p className="mb-2 text-gray-700 dark:text-gray-300">Ora: {selectedAppointment.hour}</p>
              {!selectedAppointment.isBlocked && (
                <>
                  <p className="mb-2 text-gray-700 dark:text-gray-300">
                    Nume: {selectedAppointment.firstName} {selectedAppointment.lastName}{" "}
                  </p>
                  <p className="mb-2 text-gray-700 dark:text-gray-300">Mașina: {selectedAppointment.position}</p>
                  <p className="mb-2 text-gray-700 dark:text-gray-300">Nr. tel: {selectedAppointment.phone}</p>
                </>
              )}
              {selectedAppointment.isBlocked && (
                <p className="mb-2 text-gray-700 dark:text-gray-300">Ora este blocată!</p>
              )}
              <div className="flex justify-between mt-4">
                <button
                  className="px-4 py-2 bg-red-700 text-white rounded-lg hover:bg-red-600"
                  onClick={() => handleDeleteAppointment(selectedAppointment)}
                >
                  {selectedAppointment.isBlocked ? 'Deblochează' : 'Șterge programarea'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
            <ToastContainer />

    </div>
  );
}