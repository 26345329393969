import React, { useState } from "react";
import BasicNav from "../components/basicNav";
import { auth, firestore } from '../firebase';
import { collection, doc, setDoc, onSnapshot } from 'firebase/firestore';
import Loading from "../components/loading";
import Footer from "../components/footerMain";
import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

function PricingPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    const user = auth.currentUser;
    if (!user) {
      setError('Please sign in to continue.');
      setIsLoading(false);
      return;
    }

    try {
      const checkoutSessionRef = doc(collection(firestore, 'customers', user.uid, 'checkout_sessions'));

      await setDoc(checkoutSessionRef, {
        line_items: [
          {
            price: 'price_1QKlo1A6IzncjeCxFMVJoBqb',
            quantity: 1,
          },
        ],
        payment_method_types: ['card'],
        mode: 'subscription',
        success_url: `${window.location.origin}/dashboard/settings`,
        cancel_url: window.location.origin,
      });

      const unsubscribe = onSnapshot(checkoutSessionRef, (snap) => {
        const { sessionId, error: checkoutError } = snap.data() || {};
        
        if (checkoutError) {
          setError(checkoutError.message);
          setIsLoading(false);
          unsubscribe();
          return;
        }

        if (sessionId) {
          const stripe = window.Stripe('pk_live_51OYmBEA6IzncjeCx0tUU3dEjMtZWSBlFkEW3jYyfbYWYZ2jyd7F4tz2xc3rCZU3Z5iIkS6fFumdvPgqXWQwGx8lf00Zg7FvMm0');
          stripe.redirectToCheckout({ sessionId })
            .then((result) => {
              if (result.error) {
                setError(result.error.message);
              }
            })
            .catch((error) => {
              console.error('Stripe redirect error:', error);
              setError('An error occurred during checkout. Please try again.');
            })
            .finally(() => {
              setIsLoading(false);
              unsubscribe();
            });
        }
      }, (error) => {
        console.error('Firestore onSnapshot error:', error);
        setError('An error occurred while setting up the checkout. Please try again.');
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Checkout setup error:', error);
      setError('An error occurred while setting up the checkout. Please try again.');
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate('https://app.servicegest.ro') 
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="bg-gray-50 dark:bg-slate-900 h-screen flex flex-col">
          <BasicNav />
          <main className="">
            <div className="container pt-20 mx-auto md:pt-20 lg:px-0 ">
              <button 
                onClick={handleBack} 
                className="mb-4 flex items-center text-purple-600 hover:text-purple-800 transition-colors duration-200"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Înapoi
              </button>
              <h1 className="mb-3 text-3xl font-bold text-gray-900 px-6 lg:px-0 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">
                Planul nostru de prețuri simplificat
              </h1>
              <p className="mb-6 text-lg font-normal text-gray-500 px-6 lg:px-0 sm:text-xl dark:text-gray-400">
                Toate tipurile de afaceri au nevoie de acces la resurse de dezvoltare, așa că vă oferim opțiunea de a decide cât de mult aveți nevoie să utilizați.
              </p>
              {error && (
                <div className="text-red-500 mb-4 px-6 lg:px-0">{error}</div>
              )}
              <section className="grid grid-cols-1 space-y-12 md:space-y-0 md:grid-cols-1 lg:grid-cols-1 md:gap-x-6 md:gap-6 pt-9">
                <div className="flex flex-col max-w-lg p-6 mx-auto text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:bg-gray-800 dark:text-white">
                  <h3 className="text-2xl font-semibold">ITP + Vulcanizare</h3>
                  <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Pachet Standard</p>
                  <div className="flex items-baseline my-8">
                    <span className="mr-2 text-4xl font-extrabold">50 RON</span>
                    <span className="text-gray-500 dark:text-gray-400">/lună</span>
                  </div>
                  <ul className="mb-8 space-y-4 text-left">
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>Acces la dashboard</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>Management clienți</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>Programări online</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>Acces la clienți noi prin aplicație</span>
                    </li>
                  </ul>
                  <form id="checkout-form" onSubmit={handleFormSubmit} className="self-center">
                    <button 
                      id="checkout-and-portal-button" 
                      className="self-center text-white bg-purple-700 hover:bg-purple-900 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 dark:bg-purple-700 dark:border-gray-700 dark:text-white dark:hover:bg-purple-600 me-2 mb-2" 
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Processing...' : 'Checkout'}
                    </button>
                  </form>
                </div>
              </section>
            </div>
          </main>
          <Footer />
        </section>
      )}
    </>
  );
}

export default PricingPage;