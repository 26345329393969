import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const SmartMetrics = () => {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'customers', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setMetrics(docSnap.data().metrics);
        } else {
          console.log('No such document!');
        }
      }
    };

    fetchData();
  }, []);

  if (!metrics) {
    return <div>Loading metrics...</div>;
  }

  return (
    <div className="flex md:flex-col justify-evenly gap-4">
      <div className="bg-white dark:bg-slate-900 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Programări</h3>
        <p>Anulate: {metrics.anulate}</p>
        <p>Confirmate: {metrics.confirmate}</p>
        <p>Total: {metrics.programari}</p>
      </div>
      <div className="bg-white dark:bg-slate-900 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Clienți și Mașini</h3>
        <p>Nr. de clienți: {metrics.nrClienti}</p>
        <p>Nr. de mașini: {metrics.nrMasini}</p>
      </div>
      <div className="bg-white dark:bg-slate-900 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Comunicare</h3>
        <p>Email-uri trimise: {metrics.nrEmails}</p>
        <p>Mesaje trimise: {metrics.nrMesaje}</p>
      </div>
      <div className="bg-white dark:bg-slate-900 p-4 rounded-lg shadow  hidden">
        <h3 className="text-lg font-semibold mb-2">Programări Online</h3>
        <p>Status: {metrics.nrOnline ? "Activat" : "Dezactivat"}</p>      </div>
    </div>
  );
};

export default SmartMetrics;