"use client"

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getDatabase, ref, push, onValue, update, query, orderByChild, equalTo } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const DashboardSupport = () => {
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [issueType, setIssueType] = useState("");
    const [category, setCategory] = useState("");
    const [priority, setPriority] = useState("medium");
    const [attachments, setAttachments] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userTickets, setUserTickets] = useState([]);
    const [activeTab, setActiveTab] = useState('form');
    const [isTicketsLoading, setIsTicketsLoading] = useState(true);

    const formatDate = () => {
        const now = new Date();
        return now.toISOString().split('.')[0].replace('T', ' '); // Format: "YYYY-MM-DD HH:mm:ss"
    };

    useEffect(() => {
        const auth = getAuth();
        
        const unsubscribeAuth = auth.onAuthStateChanged(user => {
            if (user) {
                const db = getDatabase();
                const ticketsRef = ref(db, 'tickets');
                const userTicketsQuery = query(ticketsRef, orderByChild('userId'), equalTo(user.uid));

                const unsubscribeTickets = onValue(userTicketsQuery, (snapshot) => {
                    const tickets = [];
                    snapshot.forEach((childSnapshot) => {
                        tickets.push({
                            id: childSnapshot.key,
                            ...childSnapshot.val()
                        });
                    });
                    console.log('Fetched tickets:', tickets);
                    setUserTickets(tickets.sort((a, b) => b.createdAt - a.createdAt));
                    setIsTicketsLoading(false);
                }, (error) => {
                    console.error('Error fetching tickets:', error);
                    setError(error.message);
                    setIsTicketsLoading(false);
                });

                return () => unsubscribeTickets();
            } else {
                setUserTickets([]);
                setIsTicketsLoading(false);
            }
        });

        return () => unsubscribeAuth();
    }, []);

    const updateTicketStatus = async (ticketId, newStatus) => {
        try {
            const db = getDatabase();
            const ticketRef = ref(db, `tickets/${ticketId}`);
            await update(ticketRef, {
                status: newStatus,
                updatedAt: formatDate()
            });
        } catch (error) {
            console.error('Error updating ticket:', error);
            setError(error.message);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User must be logged in to submit a ticket');
            }

            const db = getDatabase();
            const ticketsRef = ref(db, 'tickets');

            const ticketData = {
                subject,
                description,
                issueType,
                category,
                priority,
                status: 'open',
                createdAt: formatDate(),
                updatedAt: formatDate(),
                userId: user.uid,
                userEmail: user.email,
                attachments: attachments.length > 0 ? 'Yes' : 'No',
            };

            await push(ticketsRef, ticketData);

            setSubject("");
            setDescription("");
            setIssueType("");
            setCategory("");
            setPriority("medium");
            setAttachments([]);
            setIsSubmitted(true);
            setTimeout(() => setIsSubmitted(false), 3000);

        } catch (error) {
            console.error('Error submitting ticket:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (event) => {
        setAttachments([...event.target.files]);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.3 }
        }
    };

    const formatDisplayDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const TicketsList = () => {
        if (isTicketsLoading) {
            return (
                <div className="flex justify-center items-center p-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                </div>
            );
        }

        if (userTickets.length === 0) {
            return (
                <div className="text-center p-8 text-gray-500 dark:text-gray-400">
                    Nu există tichete în acest moment.
                </div>
            );
        }

        return (
            <div className="space-y-4">
                {userTickets.map((ticket) => (
                    <motion.div
                        key={ticket.id}
                        className="p-4 bg-white rounded-lg shadow dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        <div className="flex justify-between items-start">
                            <div>
                                <h3 className="text-lg font-medium text-gray-900 dark:text-white">{ticket.subject}</h3>
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {formatDisplayDate(ticket.createdAt)}
                                </p>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className={`px-2 py-1 text-xs rounded-full ${
                                    ticket.status === 'open' 
                                        ? 'bg-green-100 text-green-800' 
                                        : 'bg-gray-100 text-gray-800'
                                }`}>
                                    {ticket.status === 'open' ? 'Deschis' : 'Închis'}
                                </span>
                                {ticket.status === 'open' && (
                                    <button
                                        onClick={() => updateTicketStatus(ticket.id, 'closed')}
                                        className="text-sm text-purple-600 hover:text-purple-800"
                                    >
                                        Închide
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-sm text-gray-600 dark:text-gray-300">{ticket.description}</p>
                            <div className="mt-2 flex flex-wrap gap-2">
                                <span className="px-2 py-1 text-xs bg-purple-100 text-purple-800 rounded-full">
                                    {ticket.priority}
                                </span>
                                <span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                                    {ticket.category}
                                </span>
                                <span className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                                    {ticket.issueType}
                                </span>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        );
    };

    return (
        <motion.div
            id="main-content"
            className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <main className="p-4">
                <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                        <li className="mr-2">
                            <button
                                className={`inline-block p-4 ${
                                    activeTab === 'form'
                                        ? 'text-purple-600 border-b-2 border-purple-600'
                                        : 'text-gray-500 hover:text-gray-600'
                                }`}
                                onClick={() => setActiveTab('form')}
                            >
                                Ticket Nou
                            </button>
                        </li>
                        <li className="mr-2">
                            <button
                                className={`inline-block p-4 ${
                                    activeTab === 'tickets'
                                        ? 'text-purple-600 border-b-2 border-purple-600'
                                        : 'text-gray-500 hover:text-gray-600'
                                }`}
                                onClick={() => setActiveTab('tickets')}
                            >
                                Ticketele Mele
                            </button>
                        </li>
                    </ul>
                </div>

                {activeTab === 'form' ? (
                    <motion.form
                        onSubmit={handleSubmit}
                        className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <div className="mb-4">
                            <label
                                htmlFor="subject"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Subiect
                            </label>
                            <input
                                type="text"
                                id="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="issueType"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Tip Problemă
                            </label>
                            <select
                                id="issueType"
                                value={issueType}
                                onChange={(e) => setIssueType(e.target.value)}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                required
                            >
                                <option value="">Selectează tipul problemei</option>
                                <option value="bug">Bug</option>
                                <option value="feature-request">Cerere de funcționalitate</option>
                                <option value="account">Probleme cont</option>
                                <option value="billing">Facturare</option>
                                <option value="other">Altele</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="category"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Categorie
                            </label>
                            <select
                                id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                required
                            >
                                <option value="">Selectează categoria</option>
                                <option value="technical">Tehnic</option>
                                <option value="financial">Financiar</option>
                                <option value="general">General</option>
                                <option value="feedback">Feedback</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="priority"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Prioritate
                            </label>
                            <select
                                id="priority"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            >
                                <option value="low">Scăzută</option>
                                <option value="medium">Medie</option>
                                <option value="high">Ridicată</option>
                                <option value="urgent">Urgentă</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Descriere
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                rows="4"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="attachments"
                                className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                            >
                                Atașamente
                            </label>
                            <input
                                type="file"
                                id="attachments"
                                onChange={handleFileChange}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                multiple
                            />
                        </div>
                        <motion.button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Se trimite...' : 'Trimite Ticket'}
                        </motion.button>
                        <AnimatePresence>
                            {isSubmitted && (
                                <motion.p
                                    className="text-green-600 mt-2"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                >
                                    Ticketul a fost trimis cu succes!
                                </motion.p>
                            )}
                            {error && (
                                <motion.p
                                    className="text-red-600 mt-2"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                >
                                    Eroare: {error}
                                </motion.p>
                            )}
                        </AnimatePresence>
                    </motion.form>
                ) : (
                    <TicketsList />
                )}
            </main>
        </motion.div>
    );
};

export default DashboardSupport;