import React from 'react';

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const goToDayView = () => {
    toolbar.onView('day');
  };

  const goToWeekView = () => {
    toolbar.onView('week');
  };

  const goToMonthView = () => {
    toolbar.onView('month');
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          Înapoi
        </button>
        <button type="button" onClick={goToCurrent}>
          Astăzi
        </button>
        <button type="button" onClick={goToNext}>
          Înainte
        </button>
      </span>
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={goToDayView}
          className={toolbar.view === 'day' ? 'bg-purple-700 text-white' : ''}
        >
          Zi
        </button>
        <button
          type="button"
          onClick={goToWeekView}
          className={`hidden md:block ${toolbar.view === 'week' ? 'bg-purple-700 text-white' : ''}`}
        >
          Săptămână
        </button>
        <button
          type="button"
          onClick={goToMonthView}
          className={toolbar.view === 'month' ? 'bg-purple-700 text-white' : ''}
        >
          Lună
        </button>
      </span>
    </div>
  );
};

export default CustomToolbar;