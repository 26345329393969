'use client'

import React, { useState } from 'react'
import { ArrowLeft } from 'lucide-react'
import BasicNav from '../components/basicNav'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/footerMain'
import { getDatabase, push, ref, set } from "firebase/database"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const NotificationPage = () => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    email: '',
    itpExpirationDate: null
  })

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleDateChange = (date) => {
    setFormData(prevState => ({
      ...prevState,
      itpExpirationDate: date ? date.toISOString().split('T')[0] : null
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const db = getDatabase();
    const newRef = push(ref(db, 'webNotifications'));
    set(newRef, formData)
      .then(() => {
        console.log('Form submitted:', formData);
        setFormData({
          phoneNumber: '',
          email: '',
          itpExpirationDate: null
        });
        toast.success('Datele au fost înregistrate cu succes!', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: '#1e293b',
            color: '#fff',
          }
        });
        setTimeout(() => {
          handleBack();
        }, 3000);
      })
      .catch((error) => {
        console.error('Error writing to Firebase Realtime Database:', error);
        toast.error('A apărut o eroare. Vă rugăm să încercați din nou.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: '#1e293b',
            color: '#fff',
          }
        });
      });
  };

  const handleBack = () => {
    navigate(-1) 
  }

  return (
    <div className="w-full flex flex-col min-h-screen bg-white dark:bg-slate-900">
      <BasicNav />
      <div className="container mx-auto p-4 flex-grow ">
        <div className="w-full mt-14 lg:mt-20 max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <button 
              onClick={handleBack} 
              className="mb-4 flex items-center text-purple-600 hover:text-purple-800 transition-colors duration-200"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Înapoi
            </button>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">Notificări ITP</h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Introduceți datele dvs. pentru a primi notificări înainte de expirarea ITP-ului.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-col gap-4">         
                <div>
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Număr de telefon</label>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    placeholder="Număr de telefon"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Email"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label htmlFor="itpExpirationDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Data expirării ITP</label>
                  <div className="grid">
                    <DatePicker
                      id="itpExpirationDate"
                      name="itpExpirationDate"
                      selected={formData.itpExpirationDate ? new Date(formData.itpExpirationDate) : null}
                      onChange={handleDateChange}
                      required
                      placeholderText="Data expirării ITP"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <button
                    type="submit"
                    className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-colors duration-200"
                  >
                    Înregistrează-te pentru notificări
                  </button>
                </div>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                * Prin înregistrare, acceptați GDPR și prelucrarea datelor cu caracter personal.
              </p>
            </form>
          </div>
        </div>
        <div className="mt-2 text-center text-gray-600 dark:text-gray-400">
          <p>
            Vă vom trimite un memento cu 30 de zile și 24 de ore înainte de data expirării, pentru a vă asigura că aveți suficient timp să programați inspecția tehnică periodică.
          </p>
        </div>
      </div>
      <div className="text-center flex text-gray-900 dark:text-gray-400">
        <div className="px-2 w-full">
          <p className="">
            Dacă vă place această aplicație, simțiți-vă liber să <button
            onClick={() => window.open('https://donate.stripe.com/cN2eVZ3qQbRVfMk144', '_blank')}
            className="dark:text-purple-700 text-purple-700 rounded-md"
          >
              donați o cafea    
          </button>.
          </p>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default NotificationPage