import { useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { auth, firestore } from '../firebase';

function AppointmentsToday() {
    const [appointments, setAppointments] = useState([]);
  
    useEffect(() => {
      const fetchAppointments = async () => {
        const user = auth.currentUser; 
  
        if (user) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); 
  
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          tomorrow.setHours(23, 59, 59, 999);
  
          const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}.${month}.${day}`;
          };
  
          const q = query(
            collection(doc(collection(firestore, 'customers'), user.uid), 'appointments'),
            where('date', '>=', formatDate(today)),
            where('date', '<', formatDate(tomorrow))
          );
  
          const querySnapshot = await getDocs(q);
          
          let appointmentsToday = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          appointmentsToday = appointmentsToday.sort((a, b) => a.hour.localeCompare(b.hour));
        
          setAppointments(appointmentsToday);
        } else {
          console.log("User is not signed in");
        }
      };
  
      fetchAppointments();
    }, []);
    const [employees, setEmployees] = useState([]);

 
    
    // ...
    
    const fetchEmployees = async () => {
      const user = auth.currentUser;
      const q = collection(doc(collection(firestore, 'customers'), user.uid), 'employees');
      const querySnapshot = await getDocs(q);
      
      const employeesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      setEmployees(employeesData);
      console.log(employeesData);
    };
    useEffect(() => {
      fetchEmployees();
    }, []);
    return (
      <div className="w-full">
      {appointments.map(appointment => {
const employee = employees.find(emp => emp.employeeTag === appointment.employeeTag);  
        return (
          <div key={appointment.id} className="bg-gray-200 dark:bg-slate-900 shadow overflow-hidden sm:rounded-lg p-4 mb-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{appointment.firstName} {appointment.lastName}</h2>
            <p className="mt-2 text-sm text-gray-500">Ora: {appointment.hour}</p>
            <p className="mt-2 text-sm text-gray-500">Telefon: {appointment.phone}</p>
            <p className="mt-2 text-sm text-gray-500">Nr. înmatriculare: {appointment.position}</p>
            <p className="mt-2 text-sm text-gray-500">Mecanic: {employee ? `${employee.employeeName} ${employee.employeeLast}` : 'N/A'}</p>            <p className="mt-2 text-sm text-gray-500">
              Status: 
              {appointment.status === 'informat' && <span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-gray-500 mr-1 align-middle"></span>}
              {appointment.status === 'confirmat' && <span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-green-500 mr-1 align-middle"></span>}
              {appointment.status === 'anulat' && <span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-red-500 mr-1 align-middle"></span>}
              {appointment.status}
            </p>
          </div>
        );
      })}
    </div>
    );
};

export default AppointmentsToday;