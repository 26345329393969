import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style';
import Overlay from 'ol/Overlay';
import serviceMapIcon from './serviceMap3.png';

const OpenLayersServiceLocations = ({ fetchedData }) => {
  const mapRef = useRef();
  const mapInstance = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    if (!mapInstance.current) {
      mapInstance.current = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([23.594, 46.7712]), // Center of Cluj-Napoca
          zoom: 13,
        }),
      });

      // Add click event listener to the map
      mapInstance.current.on('click', handleMapClick);

      // Get user's current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userCoordinates = [position.coords.longitude, position.coords.latitude];
          addUserLocationMarker(userCoordinates);
        }, (error) => {
          console.error('Error getting user location:', error);
        });
      }
    }

    if (fetchedData) {
      geocodeAddressesAndAddMarkers();
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.setTarget(null);
        mapInstance.current = null;
      }
    };
  }, [fetchedData]);

const addUserLocationMarker = (coordinates) => {
  const vectorSource = new VectorSource();
  const feature = new Feature({
    geometry: new Point(fromLonLat(coordinates)),
    name: 'Locatia ta',
  });

  feature.setStyle(
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: 'https://cdn-icons-png.flaticon.com/512/684/684908.png', // New icon for user location
        scale: 0.07, // Adjust the scale as needed
      }),
    })
  );

  vectorSource.addFeature(feature);

  const vectorLayer = new VectorLayer({
    source: vectorSource,
  });

  mapInstance.current.addLayer(vectorLayer);
};

  const geocodeAddressesAndAddMarkers = async () => {
    const vectorSource = new VectorSource();
    const features = await Promise.all(
      Object.values(fetchedData).flat().map(async (item) => {
        const coordinates = await geocodeAddress(item.address);
        if (coordinates) {
          const feature = new Feature({
            geometry: new Point(fromLonLat(coordinates)),
            name: item.name,
            address: item.address,
            time: item.time,
          });

          feature.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: serviceMapIcon,
                scale: 0.15, // Car icon URL
              }),
            })
          );

          return feature;
        }
        return null;
      })
    );

    vectorSource.addFeatures(features.filter(feature => feature !== null));

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    mapInstance.current.addLayer(vectorLayer);
  };

  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get('https://api.opencagedata.com/geocode/v1/json', {
        params: {
          q: address,
          key: 'c61ebbbb6d0e4e209619ec5b02f7c195', // Replace with your OpenCage API key
        },
      });

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry;
        return [location.lng, location.lat];
      } else {
        console.error('Geocoding error: No results found');
        return null;
      }
    } catch (error) {
      console.error('Geocoding error:', error);
      return null;
    }
  };

  const handleMapClick = (event) => {
    const feature = mapInstance.current.forEachFeatureAtPixel(event.pixel, (feature) => feature);

    if (feature) {
      const coordinates = feature.getGeometry().getCoordinates();
      const properties = feature.getProperties();

      setSelectedPlace({
        position: coordinates,
        name: properties.name,
        address: properties.address,
        time: properties.time,
      });

      // Create or update overlay
      let overlay = mapInstance.current.getOverlayById('popup');
      if (!overlay) {
        overlay = new Overlay({
          element: document.createElement('div'),
          positioning: 'bottom-center',
          stopEvent: false,
          id: 'popup',
        });
        mapInstance.current.addOverlay(overlay);
      }

      const popupContent = `
        <div class="bg-white p-2 rounded shadow">
          <h3 class="font-bold text-lg mb-1">${properties.name}</h3>
          <p class="text-sm mb-1">${properties.address}</p>
          <p class="text-sm">Available from: ${properties.time}</p>
        </div>
      `;

      overlay.getElement().innerHTML = popupContent;
      overlay.setPosition(coordinates);
    } else {
      setSelectedPlace(null);
      const overlay = mapInstance.current.getOverlayById('popup');
      if (overlay) {
        overlay.setPosition(undefined);
      }
    }
  };

  return (
    <div ref={mapRef} style={{ width: '100%', height: '400px' }} className="mb-4"></div>
  );
};

export default OpenLayersServiceLocations;