import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Logo from "./logo";
import { auth, firestore } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import ManageBillingButton from "./ManageBillingButton";
import LogoutButton from "./logoutButton";
import NotificationDropdown from "./notifications";
import ThemeSwitcher from "./ThemeSwitcher";
import { FaBars, FaTimes } from 'react-icons/fa';

const DashboardNav = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [profilePicture, setProfilePicture] = useState(localStorage.getItem('profilePicture') || '');
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists() && 'profilePicURL' in doc.data()) {
          setProfilePicture(doc.data().profilePicURL);
        }
      }, (error) => console.error('Error fetching document:', error));
      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', String(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(prevMode => !prevMode);

  return (
    <nav className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button
              aria-label="Toggle sidebar"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-3 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </button>
            <Link to="/dashboard" className="flex ml-2 md:mr-24">
        {   /*  <Logo width="40px" height="40px" darkMode={darkMode} /> */}
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                Servicegest
              </span>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="hidden sm:flex sm:items-center sm:ml-6">
              <ThemeSwitcher darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
              <button
                type="button"
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <span className="sr-only">View notifications</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                </svg>
              </button>
              <NotificationDropdown isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />
            </div>
            <div className="relative ml-3" ref={dropdownRef}>
              <button
                type="button"
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                id="user-menu-button"
                aria-expanded={isOpen}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="sr-only">Open user menu</span>
                <img className="w-8 h-8 rounded-full" src={profilePicture} alt="user photo" />
              </button>
              {isOpen && (
                <div className="absolute -mr-4 right-0 w-48 mt-4 origin-top-right bg-white shadow-lg dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Link to="/dashboard/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                    Setări
                  </Link>
                  <ManageBillingButton />
                  <LogoutButton />
                </div>
              )}
            </div>
            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="sm:hidden" ref={mobileMenuRef}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <ThemeSwitcher darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            <button
              onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Notifications
            </button>
            <Link
              to="/dashboard/settings"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Setări
            </Link>
            <ManageBillingButton />
            <LogoutButton />
          </div>
        </div>
      )}
    </nav>
  );
};

export default DashboardNav;