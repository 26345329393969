import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import Loading from './loading'; 

export default function ManageBillingButton() {
    const [loading, setLoading] = useState(false);

function goToBillingPortal() {
    setLoading(true);
    const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

    createPortalLink({
        returnUrl: `${window.location.origin}/dashboard`,
    })
    .then(({ data }) => {
        window.location.assign(data.url);
    })
    .catch((error) => {
        console.error("Error navigating to portal link: ", error);
        setLoading(false); // Only turn off loading state in case of error
    });
}

    if (loading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <Loading />
            </div>
        );
    }

    return (
        <button 
        onClick={goToBillingPortal}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
        >
        Abonament
        </button>
    );
}