import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

const CalendarUserInfo = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(0);

  const month = currentMonth.getMonth();
  const year = currentMonth.getFullYear();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const totalAppointmentsPerDay = 8; 

  const colorClasses = {
    green: 'bg-green-500',
    yellow: 'bg-yellow-500',
    orange: 'bg-yellow-600',
    red: 'bg-red-500',
  };

  const nextMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
  };

  const prevMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
  };

  useEffect(() => {
    setFirstDayOfMonth((new Date(year, month, 1).getDay() + 6) % 7);
  }, [currentMonth, month, year]);

  useEffect(() => {
    const fetchAppointments = async () => {
      const user = auth.currentUser;
      if (user) {
        const appointmentsRef = collection(doc(collection(firestore, 'customers'), user.uid), 'appointments');
        
        const formatDate = (date) => {
          return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;
        };
        
        const startOfMonth = formatDate(new Date(year, month, 1));
        const endOfMonth = formatDate(new Date(year, month + 1, 0));
        
        try {
          const snapshot = await getDocs(query(appointmentsRef, where('date', '>=', startOfMonth), where('date', '<=', endOfMonth)));
          setAppointments(snapshot.docs.map(doc => {
            const data = doc.data();
            data.date = new Date(data.date.split('.').join('-'));
            return data;
          }));
        } catch (error) {
          console.error("Error fetching appointments: ", error);
          setAppointments([]);
        }
      } else {
        setAppointments([]);
      }
    };

    fetchAppointments();
  }, [currentMonth, month, year]);

  const renderCalendarDays = () => {
    const days = [];
    const prevMonthDays = new Date(year, month, 0).getDate();
    const startGap = firstDayOfMonth;
    const endGap = (7 - ((daysInMonth + startGap) % 7)) % 7;

    // Previous month days
    for (let i = 0; i < startGap; i++) {
      days.push(
        <div key={`prev-${i}`} className="w-10 h-10 p-2 text-center text-gray-400 self-center justify-self-center">
          {prevMonthDays - startGap + i + 1}
        </div>
      );
    }

    // Current month days
    for (let day = 1; day <= daysInMonth; day++) {
      const dayAppointments = appointments.filter(appointment => appointment.date.getDate() === day);
      const freeTimePercentage = (dayAppointments.length / totalAppointmentsPerDay) * 100;

      let color;
      if (freeTimePercentage <= 25) color = 'green';
      else if (freeTimePercentage <= 50) color = 'yellow';
      else if (freeTimePercentage <= 75) color = 'orange';
      else color = 'red';

      const currentDate = new Date(year, month, day);
      currentDate.setHours(0, 0, 0, 0);
      
      const currentToday = new Date();
      currentToday.setHours(0, 0, 0, 0);

      days.push(
        <div 
          key={day} 
          className={`w-10 h-10 p-2 text-center cursor-pointer rounded-lg self-center justify-self-center 
            ${day === selectedDate ? 'bg-blue-500 text-white' : ''} 
            ${currentDate.getTime() === currentToday.getTime() ? 'text-red-600 font-bold' : ''} 
            ${currentDate.getTime() < currentToday.getTime() ? 'opacity-50' : ''} 
            ${colorClasses[color]}`}
          onClick={() => {
            setSelectedDate(day);
            setIsModalOpen(true);
          }}
        >
          {day}
        </div>
      );
    }

    // Next month days
    for (let i = 1; i <= endGap; i++) {
      days.push(
        <div key={`next-${i}`} className="w-10 h-10 p-2 text-center text-gray-400 self-center justify-self-center">
          {i}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="w-full bg-gray-200 dark:bg-slate-900 p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <button onClick={prevMonth} className="text-2xl">&lt;</button>
        <h2 className="text-xl font-bold">
          {currentMonth.toLocaleString('ro-RO', { month: 'long', year: 'numeric' })}
        </h2>
        <button onClick={nextMonth} className="text-2xl">&gt;</button>
      </div>
      <div className="grid grid-cols-7 gap-2 mb-2">
        {['Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm', 'Dum'].map((day, index) => (
          <div key={index} className="text-center font-bold">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-2">
        {renderCalendarDays()}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 rounded-lg">
          <div className="bg-white dark:bg-slate-800 rounded-lg p-5 w-11/12 md:w-1/2 lg:w-1/2 max-h-[80vh] overflow-y-auto">
            <div className="sticky top-0 bg-white dark:bg-slate-800 mb-4 p-2">
              <span className="float-right text-2xl cursor-pointer" onClick={() => setIsModalOpen(false)}>&times;</span>
              <h2 className="text-center text-xl font-bold mb-4">
                Programări pentru: {selectedDate} {currentMonth.toLocaleString('ro-RO', { month: 'long' })}
              </h2>
            </div>
            {appointments
              .filter(appointment => appointment.date.getDate() === selectedDate)
              .sort((a, b) => a.hour.localeCompare(b.hour))
              .map(appointment => (
                <div key={appointment.id} className="mb-3 bg-gray-200 dark:bg-slate-900 p-2 rounded-lg">
                  <h3 className="font-bold">Data: {appointment.date.toLocaleDateString()}</h3>            
                  <p>Ora: {appointment.hour}</p>
                  <p>Nume: {appointment.firstName} {appointment.lastName}</p>
                  <p>Mecanic: {appointment.employeeTag}</p>
                  <p>Status: {appointment.status}</p>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarUserInfo;