import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import EditEmployee from './editEmployee';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchEmployees = async () => {
      const { uid } = auth.currentUser;
      if (uid) {
        const q = query(collection(doc(collection(firestore, 'customers'), uid), 'employees'));
        const querySnapshot = await getDocs(q);
        const employeesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEmployees(employeesData);
      }
    };

    fetchEmployees();
  }, []);

  const handleEditClick = (id) => setEditEmployeeId(id);

  const handleDelete = async (employeeId, employeeName, employeeLast) => {
    const confirmation = window.confirm(
      `Ești sigur că vrei să ștergi mecanicul ${employeeName} ${employeeLast}? În cazul în care ai programări pentru acest mecanic, acestea vor fi șterse.`
    );
    if (confirmation) {
      const { uid } = auth.currentUser;
      if (uid && employeeId) {
        const docRef = doc(firestore, 'customers', uid, 'employees', employeeId);
        await deleteDoc(docRef);
        setEmployees(employees.filter(employee => employee.id !== employeeId));
      }
    }
  };

  const handleCloseEdit = () => setEditEmployeeId(null);

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {employees.map(employee => (
        <div key={employee.id} className="bg-gray-200 p-4  w-fit rounded-lg dark:bg-slate-800">
          <h2 className="text-lg font-semibold">{employee.employeeName} {employee.employeeLast}</h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">{employee.employeeTag}</p>
          
          {location.pathname !== '/dashboard' && (
            <div className="mt-4 flex justify-between gap-2">
              <button 
                onClick={() => handleDelete(employee.id, employee.employeeName, employee.employeeLast)} 
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg text-sm"
              >
                Șterge
              </button>     
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default EmployeeList;