import React, { useState, useEffect } from 'react';
import { auth, functions, httpsCallable} from '../firebase';
import carList from './car-list.json';

function AddCar({ isOpen, onClose, clientId, setRefreshKey }) {
  
  const [inputValue, setInputValue] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [filteredCarList, setFilteredCarList] = useState([]);
  const [carDetails, setCarDetails] = useState({ model: '', marca: '', description: '' });

  useEffect(() => {
    setFilteredCarList(carList.filter(car => 
      car.brand.toLowerCase().startsWith(inputValue.toLowerCase())
    ));
  }, [inputValue]);
  if (!isOpen) {
    return null;
  }
  const handleChange = (e) => {
    if (e.target.id === 'marca') {
      setInputValue(e.target.value);
      const brand = carList.find(car => car.brand === e.target.value);
      setSelectedBrand(brand);
      console.log(brand); // Log the selected brand to the console
    } else if (e.target.id === 'model') {
      setCarDetails(prevDetails => {
        return { ...prevDetails, model: e.target.value };
      });
    }
  };
  const addCar = async (event) => {
    event.preventDefault();
    const marca = event.target.elements.marca.value;
    const model = event.target.elements.model.value;
    const description = `${marca} ${model}`;
    const position = event.target.elements["position"].value;
    const itp = event.target.elements["itp"].value;

    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) {
      console.error('User is not authenticated');
      return;
    }
  
    if (!clientId) {
      console.error('Client ID is not provided');
      return;
    }
    console.log(clientId);
    const clientID = clientId;
    const carData = { position, 
      
      description,
      itp: {
        endDate: itp,
      }
      
    };
    console.log(carData);

  const data = {
    clientID,
    carData,
  };
    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations({ operation: 'add', data: data });
      console.log(result.data);
      alert('Masina a fost adaugata cu succes!')
      setRefreshKey(prevKey => !prevKey);
      onClose();
    } catch (error) {
      console.error("Error calling clientOperations function: ", error);
    }
  };
  
    return (
<div className={`fixed left-0 inline-flex right-0 z-99 items-center bg-slate-800/70 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0  sm:h-full h-screen${isOpen ? '' : 'hidden'}`} id="editClientModal" aria-hidden="true"> 
     <div className="relative w-full max-w-2xl  md:h-auto">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          {/* Modal header */}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Adaugă mașină
            </h3>
            <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                     <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6">
            <form id="addCarForm" onSubmit={addCar}>
              <div className="grid grid-cols-6 gap-6 pb-4">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nr. Inmatriculare</label>
                  <input type="text" name="position" id="position" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="CJ72NZJ" required />
                </div>
                <div className="col-span-6 sm:col-span-3 ">
                  <label htmlFor="marca" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Marcă</label>
                  <input 
                  id="marca" 
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                  placeholder="Renault"
                  value={inputValue}
                  onChange={handleChange}
                  list="car-brands"
                />
              
               </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="model" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model</label>
                <input 
                  key={selectedBrand ? selectedBrand.brand : ''} 
                  id="model" 
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                  placeholder="Megane"
                  list={`car-models-${selectedBrand ? selectedBrand.brand : ''}`} 
                  value={carDetails.model}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                          <>
              <label htmlFor="itp" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Data expirare ITP <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
              <input 
                id="itp" 
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                placeholder="Megane"
                type="date"
                required
              />
            </>
              </div>
              </div>
              <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    );
}

export default AddCar;