import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { Link } from 'react-router-dom';

const NotificationDropdown = ({ isOpen, setIsOpen }) => {
  const [appointments, setAppointments] = useState([]);
  const [linkClicked, setLinkClicked] = useState(false);
  const dropdownRef = useRef(); 

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); 
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = () => {
    if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      const appointmentsRef = collection(firestore, 'customers', uid, 'mesaje');
  
      onSnapshot(appointmentsRef, async (snapshot) => {
        const appointments = await Promise.all(snapshot.docs.map(async appointmentDoc => {
          const appointment = { ...appointmentDoc.data(), id: appointmentDoc.id };
          
          const clientRef = doc(firestore, 'customers', uid, 'clients', appointment.clientID);
          const clientSnapshot = await getDoc(clientRef);
          if (clientSnapshot.exists()) {
            appointment.client = clientSnapshot.data();
          }
  
          const appointmentDetailsRef = doc(firestore, 'customers', uid, 'appointments', appointment.appointmentID);
          const appointmentDetailsSnapshot = await getDoc(appointmentDetailsRef);
          if (appointmentDetailsSnapshot.exists()) {
            appointment.details = appointmentDetailsSnapshot.data();
          }
  
          return appointment;
        }));
        setAppointments(appointments);
      });
    }
  };
  const deleteAppointment = async (appointmentID) => {
    if (auth.currentUser) {
      const uid = auth.currentUser.uid;

      const appointmentRef = doc(firestore, 'customers', uid, 'appointments', appointmentID);
      try {
        await deleteDoc(appointmentRef);
        fetchAppointments();
      } catch (error) {
        console.error('Error deleting appointment:', error);
      }

      const mesajeRef = collection(firestore, 'customers', uid, 'mesaje');
      const mesajeSnapshot = await getDocs(mesajeRef);
      const mesajDoc = mesajeSnapshot.docs.find(doc => doc.data().appointmentID === appointmentID);

      if (mesajDoc) {
        const mesajRef = doc(firestore, 'customers', uid, 'mesaje', mesajDoc.id);
        try {
          await deleteDoc(mesajRef);
          fetchAppointments();
        } catch (error) {
          console.error('Error deleting message:', error);
        }
      }
    }
  };
  
  return (
    <div ref={dropdownRef} onClick={(e) => e.stopPropagation()} className={`z-50 max-w-sm p-2 top-10 mt-3 overflow-y-auto absolute text-base list-none bg-white divide-y divide-gray-100 rounded shadow-lg dark:divide-gray-600 dark:bg-gray-700 block notification-dropdown ${isOpen ? '' : 'hidden'} right-2`} id="notification-dropdown" data-popper-placement="bottom"> 
      <div className="block px-4 py-2 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        Notifications
      </div>
      <div className="space-y-4">
        {appointments.map(appointment => (
          <div key={appointment.id} className="p-4 rounded shadow bg-slate-900">
      <p className="text-sm text-gray-500">Date: {appointment.details?.date}</p>
      <p className="text-sm text-gray-500">Time: {appointment.details?.hour}</p>
      <p className="text-sm text-gray-500">Position: {appointment.details?.position}</p>
      <p className="text-sm text-gray-500">Client ID: {appointment.client?.lastName}  {appointment.client?.firstName}</p>
      <p className="text-sm text-gray-500">Reprogramare: {appointment.mesaj}</p>
      <Link to="/dashboard/calendar" onClick={() => setLinkClicked(true)} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-700 hover:bg-purple-800">
  Reprogramare
</Link>
<button 
  onClick={() => linkClicked && deleteAppointment(appointment.appointmentID)} 
  className={`ml-4 inline-flex items-center px-4 py-2 border border-transparent  text-sm font-medium rounded-md shadow-sm text-white ${linkClicked ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-500 hover:bg-gray-600 cursor-not-allowed'}`}
>
  Delete
</button>
    </div>
  ))}
</div>

</div>

    );
};
export default NotificationDropdown;