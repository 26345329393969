import React from "react";

const Footer = () => {
    return (
        <footer className=" pb-0 relative bottom-0 w-full">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <span className="block text-sm text-gray-500 text-center dark:text-gray-400">Made with 💜 by Servicegest</span>
        </div>
      </footer>
    );
}

export default Footer;