import React, { useState, useEffect } from "react";
import { auth, firestore, app } from "../firebase";
import { collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import 'firebase/functions';

const DashboardMecanic = ({ closeModal }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    useEffect(() => {
        const fetchEmployees = async () => {
            const currentUser = auth.currentUser;
            const employeeCollection = collection(firestore, `customers/${currentUser.uid}/employees`);
            const employeeSnapshot = await getDocs(employeeCollection);
            setEmployees(employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
    
        fetchEmployees();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = auth.currentUser;
        if (user) {
            try {
                const selectedEmployeeData = employees.find(employee => employee.id === selectedEmployee);
    
                const data = {
                    email,
                    password,
                    parentUserId: user.uid,
                    employeeTag: selectedEmployee,
                    employeeName: selectedEmployeeData.employeeName,
                    employeeLast: selectedEmployeeData.employeeLast,
                    role: "employee",
                };
    
                // Get a reference to the Firebase Functions service
                const functions = getFunctions(app, 'europe-central2');

                // Get a reference to the createMecanic function
                const createMecanic = httpsCallable(functions, 'createMecanic');
                
                // Call the createMecanic function
                const result = await createMecanic(data);
    
                console.log('New user UID:', result.data.uid);
                console.log('Parent user UID:', user.uid);
                console.log('Selected employee:', selectedEmployee);
                console.log("Account creation success");
            } catch (error) {
                console.log("Account creation error", error);
            }
        }
    };
    return (
    <div className=" w-full ">
      <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="p-4 grid grid-cols-1 gap-4 w-full bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
      <button onClick={closeModal} className="float-right">X</button>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="employee" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Alege Mecanicul</label>
                            <select id="employee" onChange={(e) => {console.log('onChange fired'); setSelectedEmployee(e.target.value); console.log(e.target.value)}} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                {employees.map((employee) => (
                                    <option key={employee.id} value={employee.id}>
                                        {employee.employeeName} {employee.employeeLast}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input type="email" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Parola</label>
                            <input type="password" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div>
                            <button type="submit" className="bg-purple-600 text-white rounded-lg px-4 py-2 hover:bg-purple-700 focus:outline-none">Creaza contul mecanicului</button>
                        </div>
                    </form>
                    </div>
                </div>
            <div/>
        </div>
    );
};

export default DashboardMecanic;

