import { useNavigate } from 'react-router-dom';
import { auth, signOut } from '../firebase';
const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error("Error logging out: ", error.message);
        }
    };

    return (
        <button onClick={handleLogout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
            <div>Deconectare</div >
        </button>
    );
};

export default LogoutButton;