import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-slate-900 py-2 mt-auto z-50 shadow-2xl">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="md:mb-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
             Made with 💜 by Servicegest
            </p>
          </div>
               <div className="flex space-x-4">
            <a 
              href="/privacy"
              className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-300"
            >
              Politica de Confidențialitate
            </a>
            <a 
              href="/terms"
              className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-300"
            >
              Termeni și Condiții
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}