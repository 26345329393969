import React, { useState , useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardNav from "../components/dashboardNav";
import DashboardSBar from "../components/dashboardSBar";
import DashboardComp from "../components/dashboardComp";
import DashboardCalendar2 from "../components/dashboardCalendar2";
import DashboardSettings from "../components/dashboardSettings";
import DashboardSupport from "../components/dashboardSupport";
import Loading from '../components/loading';
import DashboardEmployeeCalendar from '../components/dashboardMecanicUser';
import DashboardClients2 from '../components/dashboardClients2';
import DashboardHistory2 from '../components/dashboardHistory2';

const DashboardPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [userRole, setUserRole] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 50); 
      
        return () => clearTimeout(timer); 
      }, []);
    return (
        <>
        {isLoading ? (
          <Loading />
        ) : (
          <>
                <DashboardNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <div className="flex pt-16 h-svh bg-gray-50 dark:bg-gray-900">
        <DashboardSBar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} userRole={userRole}/>
                <Routes> 
                    <Route path="/" element={<DashboardComp />} />
                    <Route path="clients" element={<DashboardClients2 />} />
                    <Route path="calendar" element={<DashboardCalendar2 />} />
                    <Route path="settings" element={<DashboardSettings />} />
                    <Route path="support" element={<DashboardSupport />} />
                    <Route path="history" element={<DashboardHistory2 />} />
                    <Route path="employeecalendar" element={<DashboardEmployeeCalendar />} />
                </Routes>
            </div>
        </>
         )}
         </>
    );
}

export default DashboardPage;