import React, { useState , useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardNav from "../components/dashboardNav";
import EmployeeSBar from '../components/employeeSBar';
import EmployeeInterventions from '../components/employeeInterventions';
import Loading from '../components/loading';
import DashboardEmployeeCalendar from '../components/dashboardMecanicUser';

const DashboardPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [userRole, setUserRole] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 50); 
      
        return () => clearTimeout(timer); 
      }, []);
    return (
        <>
        {isLoading ? (
          <Loading />
        ) : (
          <>
                <DashboardNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <div className="flex pt-16 h-svh bg-gray-50 dark:bg-gray-900">
        <EmployeeSBar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} userRole={userRole}/>
                <Routes> 
                    <Route path="/" element={<DashboardEmployeeCalendar />} />
                    <Route path="/interventions" element={<EmployeeInterventions />} />
                </Routes>
            </div>
        </>
         )}
         </>
    );
}

export default DashboardPage;